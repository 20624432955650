<template>
  <v-app>
      <!--                                                       dialog success message        -->
        <v-dialog
        v-model="dialoggood"
        width="600"
        persistent
        >
            <v-card>
                <v-card-title>
                    The City of Bremerton has been notified
                </v-card-title>
                <v-card-text>
                    <v-row justify="center">
                        Thank you for notifying the City of Bremerton!
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="home" color="success">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--                                                       dialog missing data message        -->
        <v-dialog
        v-model="dialogmissing"
        width="600"
        persistent
        >
            <v-card width="600">
                <v-card-title>
                    Missing Information
                </v-card-title>
                <v-card-text class="my-4">
                    <v-row justify="center">Please make sure that the <b class="mx-2"> 'Reason for Closing Account' </b> has been</v-row>
                    <v-row justify="center"> selected and that the <b class="mx-2">'Date of Closure'</b> has also been selected.</v-row>
                    <v-row justify="center" class="mt-4"> If the business has been sold, please include <b class="mx-2">'New Owner Info'</b>.</v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="dialogmissing=false">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--                                                       dialog error message        -->
        <v-dialog
        v-model="dialogbad"
        width="600"
        >
            <v-card>
                <v-card-title>
                    Error Sending Notice
                </v-card-title>
                <v-card-text>
                    <v-row justify="center">Thank you for trying to notify the City of Bremerton of the business closure.</v-row>
                    <v-row justify="center">Unfortanatly we are unable to process the request at this time.</v-row>
                    <v-row justify="center">Please try again later or call the city at (360) 473-5311.</v-row>
                    <v-row justify="center">You can also email us at taxinfo@ci.bremerton.wa.us</v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="home">close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

      <v-row justify="center">
          <v-col>
              <v-row justify="center">
        <v-card
            width="700"
           color="green lighten-5"
            class="mt-12"
        >
            <v-card-title>
                <v-card 
                    width="700" 
                    height="40"
                    dark
                    elevation="12"
                    color="green darken-2"
                    >
                <v-row justify="center">
                <h3 class="mt-4">Close Business Account with the City of Bremerton</h3> 
                </v-row>
                </v-card>
            </v-card-title>
            <v-card-text>
            <v-row class="mt-8" justify="center">
                <h3>Thank you for having done business in the City of Bremerton!</h3>
            </v-row>
            <v-row justify="center" class="mt-3">
                Remember, we can’t close your account until you have filed a final tax  
            </v-row>
            <v-row justify="center">    
               return covering the period through your final day of business 
            </v-row>
            <v-row justify="center">    
                and paid all outstanding balances on your account.
            </v-row>
            
                <v-row class="mt-8">
                    <v-col class="mt-6">
                        
                        
                             <h2 class="ml-2">Reason for Closing Account</h2>
                                <v-sheet 
                                    class="ml-4" 
                                    color="green lighten-5"
                                    width="270">
                                <v-radio-group v-model="closetype">
                                    <v-radio
                                        class="ma-2"
                                        label="Discontinued Doing Business in the City of Bremerton"
                                        value="discontinued"
                                    ></v-radio>
                                    <v-divider></v-divider>
                                    <v-radio
                                        class="ma-2"
                                        label="Sold Business"
                                        value="sold"
                                    ></v-radio>
                                    <v-divider></v-divider>
                                    <v-radio
                                        class="ma-2"
                                        label="Closed Business"
                                        value="closed"
                                    ></v-radio>
                                </v-radio-group>
                                </v-sheet>
                                <v-sheet
                                color="green lighten-5"
                                class="ml-4"
                                width="300"
                                v-if="closetype=='sold'"
                                >
                                    <v-textarea 
                                        class="ma-0 pa-0"    
                                        outlined
                                        height="210"
                                        placeholder="new owner name, address, and contact information"
                                        label="New Owner Info"
                                        background-color="green lighten-5"
                                        v-model="info"
                                        >
                                        
                                    </v-textarea>
                                </v-sheet>
                                
                    </v-col>
                    <v-col class="mt-6">
                        <v-row justify="center">
                           <h2>Date of Closure</h2> 
                        </v-row>
                        
                            <v-row justify="center">

                                <v-date-picker
                                color="green lighten-2" 
                                v-model="date"
                                class="mt-4"
                                ></v-date-picker>
                            </v-row>
                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-textarea 
                        class="mx-6 mt-2"    
                        outlined
                        auto-grow
                        placeholder="Any information you would like to add (optional)"
                        label="Comments"
                        background-color="green lighten-5"
                        v-model="comments"
                        >
                        
                    </v-textarea>
                </v-row>
            </v-card-text>
            <v-card-actions class="ma-6"> 
                <v-btn color="success" @click="home">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="close">submit</v-btn>
            </v-card-actions>
        </v-card>
        </v-row>
        </v-col>
      </v-row>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
    data() {
      return {
        date:'',
        closetype:'',
        info:'',
        comments:'',
        dialoggood:false,
        dialogbad:false,
        dialogmissing:false,
        }
    },
    methods:{
        home(){
            this.$router.replace({ name: "Home" });
        },
        close(){
            if(this.date && this.closetype){
                if(this.info=="" && this.closetype=="sold"){
                    this.dialogmissing=true;
                    return;
                }

                let thisvue=this;
                let params = new URLSearchParams();
                let url = this.$store.state.url;
                let prikey = this.$store.state.acct.fprikey
                let user = this.$store.state.acct.prikey
                let ex={closetype:this.closetype,info:this.info,date:this.date,comments:this.comments,user:user};
                

                ex=JSON.stringify(ex);

                params.append('ty', 'closeacnt');
                params.append('prikey', prikey);
                params.append('ex',ex);

                
                axios({
                    method:"post",
                    url:url,
                    data:params
                })
                .then(function(response){
                 //check for  inserted or no go  
                    if(response.data=="inserted"){
                        thisvue.dialoggood=true;
                    }else{
                        thisvue.dialogbad=false;
                    }
                });
            }else{
                //need to feel out date or reason
                this.dialogmissing=true;
            }
        }
    },
    mounted() {
        
        window.scrollTo(0,0);
        let a= this.$store.state.authent;
        if(!a) {
            this.$router.replace({ name: "login" });
        }
    },
}
</script>

<style>

</style>