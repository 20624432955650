<template>
  <v-app>
      <!--                     dialog to print form before payong online                    -->
        <v-dialog max-width="600" v-model="dialogdownloadpdf">
            <v-card 
                rounded
                max-width="600">
                <v-card-title>
                    Thank You!
                </v-card-title>
                <v-card-text>
                    <v-row class="ma-8">
                        Thank you for doing business in the City of Bremerton. You can download a pdf file for your tax record if you would like. Click the next 
                        button to be taken to the third party processer for your payment.
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-hover
                                v-slot:default="{ hover }"
                            >
                            <v-sheet 
                                :color="printpdfcolor" 
                                :elevation="hover ? 16 : 6"
                                rounded
                                width="250"
                                height="100"
                                @click="printpdf">
                                    <v-row class="pt-4" justify="center">Download PDF Tax Form of Your </v-row>
                                    <v-row justify="center">Current Filing for Your Records</v-row>
                                    <v-row justify="center">(optional)</v-row>
                            </v-sheet>
                            </v-hover>
                        </v-col>
                        <v-col>
                            <v-hover
                                v-slot:default="{ hover }"
                            >
                            <v-btn 
                                color="green lighten-4" 
                                :elevation="hover ? 16 : 6"
                                height="100"
                                width="250"
                                target="_blank"
                                @click="gohome2"
                                >
                                    <v-row  justify="center">
                                        <h1>Next</h1>
                                    </v-row>
                            </v-btn>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
      <!--                      error uploading to database                                  -->
        <v-dialog 
                    v-model="payinput" 
                    width="440px" 
                    persistent>
            <v-card
                class="ma-0 pa-4"
            >
                <v-card-title>
                    I'm sorry but there was an error
                </v-card-title>
                <v-card-text>
                    There was an issue saving the record to the database, so you will not be forwarded to the third party payment site.
                     No record of this transaction has been recorded. Please try again later.
                </v-card-text>
                <v-car-actions>
                    <v-btn color="error" outlined @click="payinput=false">
                        close
                    </v-btn>
                </v-car-actions>
            </v-card>
        </v-dialog>

      <!--                    tax liability    exceeds $10,000 dialog                        -->
         <v-dialog 
                    v-model="exceedslimitsdialog" 
                    width="440px" 
                    persistent>
            <v-card
                
                class="ma-0 pa-4"
            >
                <v-card-title>$10,000 Exceeds Online Payment Limit</v-card-title>
                <v-card-text>
                    <v-row justify="center">The online payment portal has a limit of $10,000 </v-row>
                    <v-row justify="center">Please use the <b class="mx-1">PAY BY MAIL OR PHONE</b> button</v-row>
                    <v-row justify="center">or call the city at (360) 473-5311</v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-car-actions >
                    <v-row class="mt-4 mr-2">
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="exceedslimitsdialog=false">Close</v-btn>
                    </v-row>    
                </v-car-actions>
            </v-card>
         </v-dialog>  

       <v-dialog v-model="paydialog" width="800px" persistent>
            <v-card>
                <v-card-title>
                    Pay {{amountpaid}} Tax 
                </v-card-title>
                <v-card-text>
                    <v-row justify="center" class="mx-4 pa-4">
                                <v-col cols="6">
                                    <v-text-field dense label="First Name" placeholder=" " v-model="acct.firstname"></v-text-field>
                                    <v-text-field dense label="Last Name" placeholder=" " v-model="acct.lastname"></v-text-field>
                                    <v-text-field dense label="Position" placeholder=" " v-model="acct.position"></v-text-field>
                                    <v-text-field dense label="Phone" placeholder=" " v-model="acct.phone"></v-text-field>
                                    <v-text-field dense label="Email" placeholder=" " v-model="acct.email"></v-text-field>

                            
                            <v-row class="ma-0 pa-0">
                                <v-col cols="1" class="ma-0 pa-0">
                                    <v-row justify="end" class="ma-0 pa-0">
                                    <v-checkbox
                                        v-model="terms"
                                        color="green"
                                        > 
                                        </v-checkbox> 
                                    </v-row>
                                </v-col>
                                <v-col >
                                    I agree to the tems and conditions for using this form
                                </v-col>
                            </v-row>
                                </v-col>
                                <v-col> <v-divider vertical></v-divider></v-col>
                                
                                <v-col cols="5" class="mt-1">
                                    <v-row justify="center" class="mb-2"><h3>Terms and Conditions</h3></v-row>
                                    <v-divider></v-divider>
                                    <v-row class="mt-2">
                                        The taxpayer is agreeing to pay the balance owing of ${{balanceforward}}.
                                    </v-row>
                                    <v-row class="my-6">
                                       <v-divider></v-divider>
                                    </v-row>
                                    <v-row>
                                        If the taxpayer is paying online, then the taxpayer agrees to pay a conveniance fee of ${{pf}} that is charged by the third party payment processor. 
                                    </v-row>
                                    <v-row>
                                        <small><b>(2.75% or a minimum of $1.25, whichever is greater)</b></small>
                                    </v-row>
                                    <v-row class="my-6">
                                       <v-divider></v-divider>
                                    </v-row>
                                    <v-row>
                                        You also have the option of mailing in payment and avoiding the third party fee of ${{pf}}.
                                    </v-row>
                                </v-col>
                                
                            </v-row>        
                           
                    
                </v-card-text>
                <v-row class="ma-0 pa-0">
                        <v-divider></v-divider>
                    </v-row>
                <v-card-actions>
                  
                    <v-row class="ma-1 pa-2">
                        <v-col>
                            <v-row justify="start">
                                <v-btn width="100" color="green" text @click="paydialog=false">back</v-btn>
                            </v-row>    
                        </v-col>    
                        <v-col>
                            <v-row justify="center" class="mr-2">
                                <v-btn 
                                    @click="paytax('m')" 
                                    v-if="terms" 
                                    width="220" 
                                    height="60"
                                    color="green lighten-3" 
                                    large><b>Pay by Mail or phone</b></v-btn>
                                <v-btn 
                                    v-if="!terms" 
                                    width="200" 
                                    height="60"
                                    color="gray" 
                                    disabled 
                                    small>pay by mail or phone</v-btn>
                            </v-row>    
                        </v-col>
                        <v-col>
                            <v-row rounded justify="end" class="mr-4">
                                <v-sheet 
                                    v-if="terms"  
                                    @click="paytax('o')" 
                                    >
                                    <v-sheet
                                    color="green lighten-3" 
                                    v-if="!tomuch"
                                    width="200" 
                                    height="60"
                                    elevation="4"
                                    rounded
                                    outlined
                                    >
                                        <v-row justify="center" class="mt-2"><b>Pay Online</b></v-row>
                                        <v-row justify="center"><small>(${{pf}} processing fee)</small></v-row>
                                    </v-sheet>

                                    <v-sheet
                                    color="red darken-3" 
                                    v-if="tomuch"
                                    dark
                                    rounded
                                    outlined
                                    width="200" 
                                    height="60"
                                    elevation="4"
                                    >
                                        <v-row class="mt-2" justify="center"><b>Cannot Pay Online</b></v-row>
                                        <v-row justify="center"><small>({{amountpaid}} exceeds $10,000)</small></v-row>
                                    </v-sheet>
                                    
                                </v-sheet>
                                <v-btn v-if="!terms" width="200" height="60" color="gray" disabled small>pay online</v-btn>
                            </v-row>    
                        </v-col>  
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-row justify="center"><pdf ref="pdf"></pdf></v-row>
  </v-app>
</template>

<script>
import axios from "axios";
import pdf from "../components/pdfbf";
export default {
data(){
    return{
        dialogdownloadpdf:false,
        paydialog:false,
        printpdfcolor:'',
        balanceforward:0,
        exceedslimitsdialog:false,
        payinput:false,
        amountpaid:'',
        acct:'',
        terms:'',
        pf:'1.50',
        tomuch:'',
        ifs:'',
        iframe:{src:'https://google.com'},
        }
    },
    components: {
        pdf,
    },
methods: {
     getaccount(){
        this.acct = this.$store.state.acct;
        this.getbalance();

      },
      getbalance(){
        this.balanceforward=this.$store.state.balanceforward;
        let x=this.balanceforward*.0275;
        this.paydialog=true; 
        if(x>1.5){
            this.pf=x.toFixed(2);
        }else{
            this.pf=1.25;
        }
        if(x>9999.99){
            this.tomuch="true";
        }else{
            this.tomuch='';
        }
    },
     paytax(x){
            let thisvue = this;
            if(this.tomuch==true && x=='o'){
                this.exceedslimitsdialog=true;
                return null;
            }
            
            thisvue.$store.commit('setAmountPaid',this.balanceforward); 
            /////////////////////////pay the tax amount
           
            let typeofpayment="";
            if(x=="m"){
                typeofpayment="Mail"
            }else{
                typeofpayment="Online"
            }

            if(this.acct.email){this.acct.email=this.acct.email.trim();}
            let p=[{"id":"Balance forward","Balance forward":this.balanceforward}];
            p=JSON.stringify(p);
            
            let url = thisvue.$store.state.url;
            const params = new URLSearchParams();
            params.append('ty', 'payonline');
            params.append('acntno' , this.acct.AcctNo);
            params.append('acntDb' , this.acct.AcctDb);
            params.append('fn' , this.acct.firstname);
            params.append('ln' , this.acct.lastname);
            params.append('po' , this.acct.position);
            params.append('ph' , this.acct.phone);
            params.append('em' , this.acct.email);
            params.append('prikey' , this.acct.fprikey);
            params.append('balanceforward' , this.balanceforward);
            params.append('typeofpayment',typeofpayment);
            params.append('items' , p);
            params.append('taxperiod','bf');
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
        
                if(response.data == "bad"){
                    thisvue.payinput=true;
                }else{
                    let pt = response.data.toString();    
                    thisvue.$store.commit('setpaytime',pt);  
                    if(x=='m'){
                        ////////////////////pay by mail
                        thisvue.$router.replace({ name: "mailpdfbf" });
                    }
                    if(x=='o'){
                        ////////////////////pay online
                        thisvue.payonline();
                    }
                }
            });
        },
        payonline(){
            this.dialogdownloadpdf=true;
            let phone = this.acct.phone.replace(/\D/g,'');
            let phone1=phone.substr(0,3);
            let phone2=phone.substr(3,3);
            let phone3=phone.substr(6,4);
            let email = this.acct.email.trim();
            let bill = this.balanceforward;
            this.iframe.src=this.$store.state.url2+"?iframe=true&header.accountNumber="+
            this.acct.AcctNo+"&header.paymentTypeCode=TAX&step=2&customer.email="+email+"&header.amount="+bill+
            "&customer.dayPhone.npa="+phone1+"&customer.dayPhone.nxx="+phone2+"&customer.dayPhone.did="+phone3+
            "&customer.firstName="+this.acct.firstname+"&customer.lastName="+this.acct.lastname+"&customer.address.zipCode="+this.acct.LocZip;
            this.ifs=this.iframe.src.replace("iframe=true&","");
        },
        printpdf(){
            this.printpdfcolor="green darken-2"
            this.$refs.pdf.getdata();
        },
        gohome2(){
        window.open(this.ifs, "_blank");
        this.$router.replace({ name: "Home" });
      },
},
mounted(){
      window.scrollTo(0,0);
      let a= this.$store.state.authent;
      if(!a) {
        this.$router.replace({ name: "login" });
      }else{
        this.getaccount();
      }
    }
}
</script>

<style>

</style>