<template>
  <v-app>
        <v-sheet 
            width="100%"
            height="100%"
            color="blue">
            <iframe
                    src="https://landlords.bremertonwa.gov/#/loggedin"
                    style="width:100%;height:100%"
                    frameborder="0" >
           </iframe>
        </v-sheet>
  </v-app>
</template>

<script>

export default {


    mounted(){
        window.console.log("hi");
        let a=this;
        window.console.log(a);
    }
}

   

</script>

<style>

</style>