<template>
  <v-app>
<!-- /////////////////////////////////////////////////////////////////update Activity status dialog -->
    <v-dialog width="600" height="350" v-model="dialogActivity">
        <v-card>
            <v-card-title>{{dialogActivityMsg}}</v-card-title>
            <v-card-text>
                {{dialogActivityText}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="dialogActivity=false">Okay</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
<!--  ///////////////////////////////////////////////////////////////end update status dialog  -->      
<!-- /////////////////////////////////////////////////////////////////update status dialog -->
    <v-dialog width="600" height="350" v-model="dialogmarkpaid">
        <v-sheet color="amber lighten-5" class="pa-4 ">
            <v-radio-group
                v-model="clickedon.status"
                >
                <v-radio
                label="Paid (update record, add transaction, add activity)"
                value="Paid"
                ></v-radio>
                <v-radio
                label="NSF (update record, add transaction)"
                value="NSF"
                ></v-radio>
                <v-radio
                label="Lost in Mail (update record, add transaction)"
                value="Lost"
                ></v-radio>
                <v-radio
                label="Duplicate (update record)"
                value="Duplicate"
                ></v-radio>
                <v-radio
                label="Mistake (update record)"
                value="Mistake"
                ></v-radio>
                <v-radio
                label="Pending (update record)"
                value="Pending"
                ></v-radio>
                
                
                </v-radio-group>
            <v-divider></v-divider>
            <v-row class="ma-2">
                    <v-textarea
                    outlined
                    filled
                    label="Notes"
                    auto-grow
                    id="statusnotes"
                    :value="clickedon.notes"
                    ></v-textarea>
            </v-row>    
            <v-row class="ma-2">
                <v-text-field
                outlined
                v-model="inputuser"
                label="Name of person updating record"
                
                >test</v-text-field>
            </v-row>
            <v-row class="ml-4">
                <v-btn color="warning" @click="markpaid">Update!</v-btn>
                <v-spacer></v-spacer>
                <v-btn elevation="12" color="success" @click="dialogmarkpaid=false">Close</v-btn>
            </v-row>
        </v-sheet>
    </v-dialog>
<!--  ///////////////////////////////////////////////////////////////end update status dialog  -->

<!-- /////////////////////////////////////////////////////////////////clicked on popup
////////////////////////////////////////////////////////////////////// -->
    <v-dialog width="700" height="700" v-model="dialog">
        <v-card
          min-width="700"
          max-width="700"
          >
        <v-card-title>{{clickedon.BusName}}
            <v-spacer></v-spacer>
            
        </v-card-title>

        <v-card-text>
            <v-row class="ml-3">
                <h4>Status : {{clickedon.status}}</h4>
            </v-row>
            <v-row class="ml-3 mt-2 mr-3" >
                <v-textarea
                outlined
                dense
                auto-grow
                readonly
                disabled
                label="Portal Payment Notes"
                :value="clickedon.notes"
                ></v-textarea>
            </v-row>
            <v-row class="my-2">
                <v-divider></v-divider>
            </v-row>
            <v-row >
                <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
                >
                    <v-tab  href="#tab-1">Portal User</v-tab>
                    <v-tab  href="#tab-2">Taxes Paid</v-tab>
                    <v-tab  href="#tab-3">Business</v-tab>
                </v-tabs>   
                
                <v-tabs-items v-model="tab">
<!-- /////////////////////////////////////////////////////////////Portal User -->                  
                <v-tab-item value="tab-1">
                    <v-card 
                        min-width="675"
                        max-width="675"
                        color="amber lighten-4"
                        class="ma-0 pa-0"
                        elevation="12"
                        >
                        <v-card-title>Portal User</v-card-title>
                        <v-card-text class="ma-0 pa-0">
                            <v-text-field
                                class="ma-0 pa-0 mx-2"
                                v-model="clickedon.firstname"
                                label="Firstname"
                                dense
                                outlined
                                readonly
                                background-color="amber lighten-5"
                            ></v-text-field>
                            <v-text-field
                                class="my-0 py-0 mx-2"
                                v-model="clickedon.lastname"
                                label="Lastname"
                                dense
                                outlined
                                readonly
                                background-color="amber lighten-5"
                            ></v-text-field>
                            <v-text-field
                                class="my-0 py-0 mx-2"
                                v-model="clickedon.phone"
                                label="Phone"
                                dense
                                outlined
                                readonly
                                background-color="amber lighten-5"
                            ></v-text-field>
                            <v-text-field
                                class="my-0 py-0 mx-2"
                                v-model="clickedon.payemail"
                                label="Email"
                                dense
                                outlined
                                readonly
                                background-color="amber lighten-5"
                            ></v-text-field>
                            <v-text-field
                                class="my-0 py-0 mx-2"
                                v-model="clickedon.positn"
                                label="Position"
                                dense
                                outlined
                                readonly
                                background-color="amber lighten-5"
                            ></v-text-field>
                            
                        </v-card-text>
                    </v-card>
                </v-tab-item>
<!-- ///////////////////////////////////////////////////////////////// end portal user -->
<!-- /////////////////////////////////////////////////////////////////start taxes paid  -->
                <v-tab-item value="tab-2">
                    <v-card
                        min-width="675"
                        max-width="675"
                        color="amber lighten-4"
                        class="ma-0 pa-0"
                        elevation="12">
                        <v-card-title>Taxes Paid</v-card-title>
                        <v-card-text>
                            <v-row class="ma-4 pa-4">
                                <v-divider></v-divider>
                            </v-row>
                            <v-row >
                                <v-col class="ml-3 pa-1" cols="4">
                                    Calculated on:
                                </v-col>
                                <v-col class="ma-0 pa-1" cols="7">
                                    {{clickedon.calculatedate}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Due Date:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    {{clickedon.datedue}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Tax Period:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    {{clickedon.taxperiod}}
                                </v-col>
                            </v-row>
                            <!-- taxdetails  -->
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Tax Gross:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    ${{clickedon.taxdetails.gross.toFixed(2)}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Tax Deductions:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    ${{clickedon.taxdetails.deductions.toFixed(2)}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Tax Exemptions:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    ${{clickedon.taxdetails.exemptions.toFixed(2)}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Taxable Ammount:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    ${{clickedon.taxdetails.taxableamount.toFixed(2)}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1"  cols="4">
                                    Tax Total:
                                </v-col>
                                <v-col class="ma-0 pa-1"  cols="7">
                                    {{clickedon.totaltax}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1" cols="4">
                                    Penalty:
                                </v-col>
                                <v-col class="ma-0 pa-1" cols="7">
                                    {{clickedon.penalty}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1" cols="4">
                                    Previous:
                                </v-col>
                                <v-col class="ma-0 pa-1" cols="7">
                                    {{clickedon.balanceforward}}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="ml-3 pa-1 mb-10" cols="4">
                                    Grand Total:
                                </v-col>
                                <v-col class="ma-0 pa-1" cols="7">
                                    {{clickedon.grandtotal}}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
<!--//////////////////////////////////////////////////////////////////end taxes paid  -->
<!-- /////////////////////////////////////////////////////////////////start business details -->
                <v-tab-item value="tab-3">
                    <v-card
                        min-width="675"
                        max-width="675"
                        color="amber lighten-4"
                        class="ma-0 pa-0"
                        elevation="12">
                        <v-card-title>Business Details</v-card-title>
                        <v-row>
                            <v-col>
                                <v-row class="ml-8">
                                {{clickedon.BusName}} 
                                </v-row>
                                <v-row class="ml-8">
                                    {{clickedon.BusDesc}}
                                </v-row>
                                <v-row class="ml-8">
                                    {{clickedon.DBAName}}
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row class="ml-8">
                                Business Phone: {{clickedon.LocPhone}} 
                                </v-row>
                                <v-row class="ml-8">
                                FaxPhone: {{clickedon.FaxPhone}}
                                </v-row>
                                <v-row class="ml-8">
                                Homeloc:  {{clickedon.Homeloc}}
                                </v-row>
                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row justify="center">
                                <v-card
                                 min-width="300"
                                max-width="300"
                                color="amber lighten-5"
                                class="ma-0 pa-0"
                                elevation="12">
                                <v-card-title>Mail Address</v-card-title>
                                <v-card-text>
                                    {{m1}}<br/>
                                    {{m2}}<br/>
                                    {{m3}}<br/>
                                    {{m4}}<br/>
                                    {{m5}}<br/>
                                    {{m6}}
                                </v-card-text>
                                </v-card>
                                </v-row>
                            </v-col>
                            <v-col>
                                <v-row justify="center">
                                <v-card
                                 min-width="300"
                                max-width="300"
                                color="amber lighten-5"
                                class="ma-0 pa-0"
                                elevation="12">
                                <v-card-title>Location</v-card-title>
                                <v-card-text>
                                    {{l1}}<br/>
                                    {{l2}}<br/>
                                    {{l3}}<br/>
                                    {{l4}}<br/>
                                    {{l5}}<br/>
                                    {{l6}}
                                </v-card-text>
                                </v-card>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row class="ml-8 my-0 py-0">
                            <v-col cols="12" class="ma-0 pa-0">
                                <h3>Business Notes:</h3>
                            </v-col>
                            
                                <v-col class="ma-0 pa-0" cols="12" v-for="n in businessnotes" v-bind:key="n.entryDate">
                                    
                                        {{n.entryDate}}
                                        {{n.note}}
                                    
                                </v-col>
                            
                        </v-row>
                        
                    </v-card>
                </v-tab-item>
<!-- /////////////////////////////////////////////////////////////////end business details -->
                </v-tabs-items>
            </v-row>
            </v-card-text>
            <v-card-actions>
                <v-hover v-slot:default="{ hover }" >
                    <v-btn v-if="clickedon.status != 'Paid'" color="warning" :elevation="hover ? 24 : 2" @click="premarkpaid()">Update</v-btn>
                </v-hover>
                <v-spacer></v-spacer>
                <v-btn elevation="12" color="success" @click="printpdf">Print Tax Form</v-btn>
                <v-btn elevation="12" color="success" @click="dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>

      </v-dialog>

      <v-row justify="center">
            <v-card
            color="amber lighten-4"
            class="pa-8"
            >
                <v-card-title >
                    <v-row class="mx-8">
                        <v-col cols="5">
                           <v-row>Online Payments </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn color="success" elevation="12" @click="getonlinepayments()">Pending</v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn color="success" elevation="12" @click="getonlinepayments('all')">All</v-btn>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col>    
                            <v-spacer></v-spacer>
                            <v-text-field
                            style="width:150px"
                            outlined
                            dense
                            v-model="docno" 
                            placeholder=" " 
                            label="Search Document No."
                            @input="getonlinepayments('d')"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            style="width:150px"
                            outlined
                            dense
                            v-model="acntno" 
                            placeholder=" " 
                            label="Search Account No."
                            @input="getonlinepayments('a')"
                            ></v-text-field>
                        </v-col>    
                    </v-row> 
                    

                </v-card-title>

         
                <v-card-text >

                    <v-row justify="center">
                        <v-data-table
                        :headers="headers"
                        :items="mailpayments"
                        :items-per-page="15"
                        :search="search"
                        item-class="color"
                        @click:row="getinfo"
                        
                        >
                        <template v-slot:top>
                            <v-text-field
                            v-model="search"
                            label="Search "
                            class="mx-4"
                            ></v-text-field>
                        </template>
                        </v-data-table>
                    </v-row>
                </v-card-text>
                
            </v-card>
      </v-row>
      <pdf ref="pdf"></pdf>
  </v-app>
</template>

<script>
import axios from "axios";
import pdf from "../components/pdf";
export default {
    components:{
        pdf,
    },
    data() {
      return {
          inputuser:' ',
          tab:'',
          search:'',
          dialogActivity:false,
          dialogActivityMsg:"",
          dialogActivityText:"",
          dialog:false,
          dialogmarkpaid:false,
          docno:"",
          acntno:"",
          note:"",
          m1:"",
          m2:"",
          m3:"",
          m4:"",
          m5:"",
          m6:"",
          l1:"",
          l2:"",
          l3:"",
          l4:"",
          l5:"",
          l6:"",
          activity:{loc:'',process:'',batchid:'',day:'',bat:'',ent:'',ending:'',per:'',type:'',code:'',tran:'',gross:'',Deduction:'',Exemption:'',Base:'',distrib:'',reference:''},
          newt:{batch:'',entry:'',reference:'',amount:'',code:'',status:'',comment:'',user:''},
          businessnotes:[],
          statusitems: ['pending', 'paid', 'bad check', 'paid online', 'lost in mail'],
          clickedon:{taxdetails:{"gross":0,"deductions":0,"exemptions":0,"taxableamount":0}},
          mailpayments:[],
          headers: [
          { text: 'Doc No.', value: 'prikey' },
          { text: 'Status', value: 'status' },
          {
            text: 'Account',
            align: 'start',
            value: 'accnt',
            
          },
          { text: 'Business', value: 'BusName' },
          { text: 'Log Date', value: 'calculatedate' },
          { text: 'Due Date', value: 'datedue' },
          { text: 'Tax Period', value: 'taxperiod' },
          { text: 'Total Tax', value: 'totaltax' },
          { text: 'Penalty', value: 'penalty' },
          { text: 'Prev Bal', value: 'balanceforward' },
          { text: 'Grand Total', value: 'grandtotal' },
        ],
      }
    },
    methods:{
        printpdf(){
            this.$refs.pdf.getdata();
        },
        premarkpaid(){
            this.dialogmarkpaid=true;
        },
        markpaid(){

            this.dialogActivityText="";
            this.clickedon.notes=document.getElementById('statusnotes').value;
            let thisvue=this;
            let params = new URLSearchParams();
            let url = this.$store.state.url;
            params.append('ty', 'markpaid');
            params.append('prikey', this.clickedon.prikey);
            params.append('Status',this.clickedon.status);
            params.append('note', this.clickedon.notes);
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
                thisvue.dialogmarkpaid=false;
                thisvue.dialog=false;
                if(response.data == "okay"){
                    thisvue.dialogActivity=true;
                    thisvue.dialogActivityMsg="Payments Table";
                    thisvue.dialogActivityText=thisvue.dialogActivityText+"Updated Payment Status";
                    let s=thisvue.clickedon.status;
                    window.console.log(s);
                        if(s=="Duplicate" || s=="Mistake" || s=="Pending"){
                            thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                            thisvue.dialogActivityText=thisvue.dialogActivityText+" No Other Updates Pending";
                        }else{
                            thisvue.newtransaction();
                            thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                            thisvue.dialogActivityText=thisvue.dialogActivityText+"  Updateing Transaction Table ...";
                        }
                    }else{
                        window.alert("sorry charlie");
                        thisvue.dialogActivityMsg="Error in Transaction Table";
                        thisvue.dialogActivityText=response.data;
                    }
            });
        },
        newtransaction(){
            //////////////////////////this will insert a row into the transaction table
            let thisvue=this;
            let code="";
            let n=document.getElementById("statusnotes").value;
            this.clickedon.notes=n;
            this.clickedon.status=="Paid"?code="Pay":code="COM";

            this.newt.batch='0';
            this.newt.entry='0';
            this.newt.reference='Mail';
            this.newt.amount=this.clickedon.grandtotal;
            this.newt.code=code;
            this.newt.status=this.clickedon.status;
            this.newt.comment=n;
            
            this.newt.user=this.inputuser;

            let params = new URLSearchParams();
            let url = this.$store.state.url;
            let t=JSON.stringify(this.newt);
            params.append('ty', 'newtransaction');
            params.append('acnt', this.clickedon.accnt);
            params.append('acntDb',this.clickedon.AcctDb);
            params.append('transaction',t);
            params.append('TransDate',this.clickedon.submitdate.date);
            
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
              if(response.data=="dup"){
                thisvue.dialogActivity=true;
                thisvue.dialogActivityMsg="Transaction Table";
                thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                thisvue.dialogActivityText=thisvue.dialogActivityText+"DUPLICATE DATA!! This data has not been added to the transaction table.";
              }
              else if(parseInt(response.data)>0){
                thisvue.dialogActivity=true;
                thisvue.dialogActivityMsg="Transaction Table";
                thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                thisvue.dialogActivityText=thisvue.dialogActivityText+"Data has been added to the Transaction table";
                let s=thisvue.clickedon.status;
                window.console.log(s)
                if(s=="Paid"){
                    window.console.log("Entering new activity");
                    //thisvue.newactivity();
                }
              }
              else{
                thisvue.dialogActivity=true;
                thisvue.dialogActivityMsg="Error in Transaction Table";
                thisvue.dialogActivityText=response.data;
              }
            });
        },
        newactivity(){
            ///////////////////////////////this just get a dummy retern of okay since all activity is added thru the batch entries. We could delete this.

            ////////////////this will insert a row into the activity table
            thisvue=this;
            thisvue.dialogActivityMsg="Activity Table";
            this.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
            this.dialogActivityText=thisvue.dialogActivityText+"Updating Activity Table ...";
            
            /////////////////////activity parameters
            this.activity.loc='1801';
            let cod=new Date(this.clickedon.submitdate.date);
            let mon;
            cod.getMonth()+1<10?mon="0"+(cod.getMonth()+1).toString():mon=(cod.getMonth()+1).toString();
            this.activity.process=cod.getFullYear().toString()+mon;
            this.activity.batchid=this.clickedon.prikey.toString();
            this.activity.day=cod.getDate().toString();
            this.activity.bat='1';
            this.activity.ent='1';
            let pA=this.clickedon.taxperiod.split(" ");
            if(pA[0] == "January"){pA[0]="01";}
            if(pA[0] == "February"){pA[0]="02";}
            if(pA[0] == "March"){pA[0]="03";}
            if(pA[0] == "April"){pA[0]="04";}
            if(pA[0] == "May"){pA[0]="05";}
            if(pA[0] == "June"){pA[0]="06";}
            if(pA[0] == "July"){pA[0]="07";}
            if(pA[0] == "August"){pA[0]="08";}
            if(pA[0] == "September"){pA[0]="09";}
            if(pA[0] == "October"){pA[0]="10";}
            if(pA[0] == "November"){pA[0]="11";}
            if(pA[0] == "December"){pA[0]="12";}
            if(pA[0] == "Q1"){pA[0]="03";}
            if(pA[0] == "Q2"){pA[0]="06";}
            if(pA[0] == "Q3"){pA[0]="09";}
            if(pA[0] == "Q4"){pA[0]="12";}
            if(pA[0] == "Annual"){pA[0]="12";}

            this.activity.tran="00";
            this.activity.ending=pA[1].toString()+pA[0].toString();
            this.activity.per=pA[0];
            this.activity.type="Mail";
            this.activity.code=this.clickedon.status;
            this.activity.distrib=this.clickedon.grandtotal.replace('$','');
            this.activity.reference=document.getElementById('statusnotes').value;
            this.activity.AcctDb = this.clickedon.AcctDb;
            this.activity.AcctNo = this.clickedon.accnt;
            this.activity.Exemption = this.clickedon.taxdetails.exemptions;
            this.activity.gross = this.clickedon.taxdetails.gross;
            this.activity.Deduction = this.clickedon.taxdetails.deductions;
            this.activity.Base = this.clickedon.taxdetails.taxableamount;

            
            let thisvue=this;
            let params = new URLSearchParams();
            let url = this.$store.state.url;
            let a=JSON.stringify(this.activity);
            params.append('ty', 'newactivity');
            params.append('activity',a);
            
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
                thisvue.dialogNT=false;
                if(response.data == 'okay'){
                    thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                    thisvue.dialogActivityText=thisvue.dialogActivityText+"Data has been added to the Activity table";
                }else if(response.data== 'dup'){
                    thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                    thisvue.dialogActivityText=thisvue.dialogActivityText+"Data is a DUPLICATE in the Activity table, Activity table is unchanged!!";

                
                }else{
                    thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                    thisvue.dialogActivityText=thisvue.dialogActivityText+"ERROR!!!!!!";
                    thisvue.dialogActivityText=thisvue.dialogActivityText+" ______________________________________________________________________________________ ";
                    thisvue.dialogActivityText=thisvue.dialogActivityText+response.data;
                    thisvue.dialogActivityText=thisvue.dialogActivityText+"Rolling back transaction";
                    ////error handling and rollback transactions and payonline status
                    window.console.log("starting to roll back");
                }
            });
        
      },
        getbusinessnotes(){
            let thisvue=this;
            let params = new URLSearchParams();
            let url = this.$store.state.url;
            params.append('ty', 'notes');
            params.append('acnt', this.clickedon.accnt);
            params.append('acntDb',this.clickedon.AcctDb);
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
                thisvue.businessnotes=response.data;
              
            });
        },
        getinfo(x){
            this.clickedon=x;
            this.$store.commit('setDocno',x.prikey);
            this.dialog=true;
            let pay=JSON.parse(this.clickedon.pay);
            let p;
            let o=new Array;
            let g=0;
            let d=0;
            let e=0;
            let ta=0;
            let td=0;
            for(p in pay){
                o=Object.values(pay[p]);
                if(o[1]){g+=parseFloat(o[1]);}
                if(o[2]){d+=parseFloat(o[2]);}
                if(o[3]){e+=parseFloat(o[3]);}
                if(o[4]){ta+=parseFloat(o[4]);}
                if(o[5]){td+=parseFloat(o[5]);}
            }
            this.clickedon.taxdetails={'gross':g,'deductions':d,'exemptions':e,'taxableamount':ta,'taxdue':td};

            //////////////////////////fix mail address
            let m1=x.MailAdr1;
            let m2=x.MailAdr2;
            let m3=x.MailAdr3;
            let m4=x.MailCity;
            let m5=x.MailState;
            let m6=x.MailZip;
            this.m1=m1;this.m2=m2;this.m3=m3;this.m4=m4;this.m5=m5;this.m6=m6;
            if(!m3){this.m3=m4;this.m4=m5;this.m5=m6;m3=m4;m4=m5;m5=m6;this.m6="";}
            if(!m2){this.m2=m3;this.m3=m4;this.m4=m5;this.m5="";}
            //////////////////////////fix location address
            let l1=x.LocAdr1;
            let l2=x.LocAdr2;
            let l3=x.LocAdr3;
            let l4=x.LocCity;
            let l5=x.LocState;
            let l6=x.LocZip;

            this.l1=l1;this.l2=l2;this.l3=l3;this.l4=l4;this.l5=l5;this.l6=l6;
            if(!l3){this.l3=l4;this.l4=l5;this.l5=l6;l3=l4;l4=l5;l5=l6;this.l6="";}
            if(!l2){this.l2=l3;this.l3=l4;this.l4=l5;this.l5="";}

            this.getbusinessnotes();

        },
        getonlinepayments(w){
            let where;
            if(w=="all"){
                where="1=1";
            }
            if(w=="" || !w){
                where="status = 'Pending'";
            }
            if(w=="d"){
                if(this.docno){
                    where="prikey="+this.docno;
                }
            }
            if(w=="a"){
                if(this.acntno){
                    where="accnt="+this.acntno;
                }
            }
            let dt=new Date;
            let dt2="";
            let thisvue=this;
            let params = new URLSearchParams();
            let url = this.$store.state.url;
            params.append('ty', 'getallonlinepayments');
            params.append('where', where);
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
    if(response.data){
        
                for(let i=0;i<response.data.length;i++){
                    response.data[i].companyprikey=response.data[i].companyprikey.trim();
                    response.data[i].firstname=response.data[i].firstname.trim();
                    response.data[i].lastname=response.data[i].lastname.trim();
                    response.data[i].phone=response.data[i].phone.trim();
                    response.data[i].positn=response.data[i].positn.trim();
                    response.data[i].accnt=response.data[i].accnt.trim();
                    dt=new Date(response.data[i].submitdate.date);
                    dt2=(dt.getMonth()+1)+"/"+dt.getDate()+"/"+dt.getFullYear();
                    response.data[i].calculatedate=dt2;

                    if(response.data[i].status=="" || !response.data[i].status){
                        response.data[i].status="Pending";
                    }
                    response.data[i].color="white";
                }
                thisvue.mailpayments=response.data;
            }else{
                thisvue.mailpayments=[];
            }
            });
        }
    },
    mounted(){
         window.scrollTo(0,0);
        this.getonlinepayments();
    },
}
</script>

<style>

</style>