<template>
  <v-app>
        <v-snackbar
        v-model="snackbar"
        elevation="12"
        centered
        timeout="30000"
        shaped
        min-height="200"
        >
        <h3>{{ snackbarm }}</h3>

        <template v-slot:action="{ attrs }">
            <v-btn
            color="amber darken-4"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
<!--                     dialog for zero tax filing                                   -->

<v-dialog max-width="600" v-model="dialogemptytax">
    <v-card 
        rounded
        elevation="24"
        color="amber lighten-4"
        max-width="600">
        <v-card-title>
            Zero Balance Return
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-row class="ma-8">
                At least one field for gross amount must have a number entered, even if that number is zero.
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-row justify="end" class="mx-5 mb-2">
                <v-btn elevation="12" color="success" @click="dialogemptytax=false">close</v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
</v-dialog>

<!--                     dialog to print form before payong online                    -->
<v-dialog max-width="600" v-model="dialogdownloadpdf">
    <v-card 
        rounded
        max-width="600">
        <v-card-title>
            Thank You!
        </v-card-title>
        <v-card-text>
            <v-row class="ma-8">
                Thank you for doing business in the City of Bremerton. You can download a pdf file for your tax record if you would like. Click the next 
                button to be taken to the third party processer for your payment.
            </v-row>
            <v-row>
                <v-col>
                    <v-hover
                        v-slot:default="{ hover }"
                    >
                    <v-sheet 
                        :color="printpdfcolor" 
                        :elevation="hover ? 16 : 6"
                        rounded
                        width="250"
                        height="100"
                        @click="printpdf">
                            <v-row class="pt-4" justify="center">Download PDF Tax Form of Your </v-row>
                            <v-row justify="center">Current Filing for Your Records</v-row>
                            <v-row justify="center">(optional)</v-row>
                    </v-sheet>
                    </v-hover>
                </v-col>
                <v-col>
                    <v-hover
                        v-slot:default="{ hover }"
                    >
                    <v-btn 
                        color="green lighten-4" 
                        :elevation="hover ? 16 : 6"
                        height="100"
                        width="250"
                        target="_blank"
                        @click="gohome2"
                        >
                            <v-row  justify="center">
                                <h1>Next</h1>
                            </v-row>
                    </v-btn>
                    </v-hover>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</v-dialog>
<!--                     dialog to print form before payong online                    -->
<v-dialog persistent max-width="600" v-model="dialogdownloadpdf2">
    <v-card 
        rounded
        max-width="600">
        <v-card-title>
            Success!
        </v-card-title>
        <v-card-text>
            <v-row class="ma-8">
                Thank you for doing business in the City of Bremerton. Your document number is {{docno}} and can be found at the bottom of your pdf file. You can download the pdf file for your tax record if you would like.
            </v-row>
            <v-row>
                <v-col>
                    <v-hover
                        v-slot:default="{ hover }"
                    >
                    <v-sheet 
                        :color="printpdfcolor" 
                        :elevation="hover ? 16 : 6"
                        rounded
                        width="250"
                        height="100"
                        @click="printpdf">
                            <v-row class="pt-4" justify="center">Download PDF Tax Form of Your </v-row>
                            <v-row justify="center">Current Filing for Your Records</v-row>
                            <v-row justify="center">(optional)</v-row>
                    </v-sheet>
                    </v-hover>
                </v-col>
                <v-col>
                    <v-hover
                        v-slot:default="{ hover }"
                    >
                    <v-btn 
                        color="green lighten-4" 
                        :elevation="hover ? 16 : 6"
                        height="100"
                        width="250"
                        target="_blank"
                        @click="gohome"
                        >
                            <v-row  justify="center">
                                <h1>home</h1>
                            </v-row>
                    </v-btn>
                    </v-hover>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</v-dialog>
<!--                    tax liability    exceeds $10,000 dialog                        -->
         <v-dialog 
                    v-model="exceedslimitsdialog" 
                    width="440px" 
                    persistent>
            <v-card
                
                class="ma-0 pa-4"
            >
                <v-card-title>$10,000 Exceeds Online Payment Limit</v-card-title>
                <v-card-text>
                    <v-row justify="center">The online payment portal has a limit of $10,000 </v-row>
                    <v-row justify="center">Please use the <b class="mx-1">PAY BY MAIL OR PHONE</b> button</v-row>
                    <v-row justify="center">or call the city at (360) 473-5311</v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-car-actions >
                    <v-row class="mt-4 mr-2">
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="exceedslimitsdialog=false">Close</v-btn>
                    </v-row>    
                </v-car-actions>
            </v-card>
         </v-dialog>  

<!--                                            paymentus info and link        -->
         <v-dialog class="ma-0 pa-0" 
                    v-model="payonlineinfodialog" 
                    min-width="700px" 
                    max-width="700px" 
                    persistent>
            <v-card
                
                min-width="700"
                class="ma-0 pa-0"
            >
                <v-card-title>Paymentus Payment Processing</v-card-title>
                <v-card-text>
                <v-row class="ma-10">
                    <v-col><v-row justify="end">Online payments are processed thru Paymentus. More information about Paymentus can be found by clicking the Paymentus Info Button</v-row>  </v-col>    
                    <v-col>
                        <v-btn 
                            class="ma-3 pa-3" 
                            color="success" 
                            target="_blank" 
                            href="https://www.paymentus.com/"
                        >Paymentus Info</v-btn>
                    </v-col>
                
                </v-row> 
                <v-row  class="ma-10"> 
                    <v-col><v-row justify="end">To access Paymentus payment processing directly, please click here:</v-row></v-col>
                    <v-col>
                    <v-btn
                        :href="ifs"
                        target="_blank"
                        color="success"
                        class="ma-3 pa-3" 
                    >Paymentus Page</v-btn>
                    </v-col>
                </v-row>     
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-row class="ma-8">
                    <v-spacer></v-spacer>
                    <v-btn color="warning" @click="payonlineinfodialog = false">close</v-btn>
                    </v-row>
                    
                </v-card-actions>
            </v-card>
        </v-dialog>    
<!--   //////////////////////////////////////////////////////////////////////////////  -->
         <v-dialog class="ma-0 pa-0" 
                    v-model="payonlinedialog" 
                    min-width="800px" 
                    max-width="800px" 
                    persistent>
            <v-card
                min-height="900"
                min-width="800"
                class="ma-0 pa-0"
            >
                <v-card-title></v-card-title>
                <v-card-text>
                    
                        <iframe ref="paymentus" id="paymentus" class="ma-0 pa-0" width="750" height="850" :src="iframe.src" frameborder="0" />
                    
                </v-card-text>
                <v-card-actions>
                    
                    
                    
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="checkiframeinfo">Paymentus Information</v-btn>
                    <v-btn color="success" @click="checkiframe">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>    

        <v-dialog v-model="exemptiontotaldialog" width="800px" persistent>
            <v-card>
                <v-card-title>
                    Exemptions Exceeded
                </v-card-title>
                <v-card-text>
                    <v-row >
                      <v-col cols="2" class="text-right my-0 py-0"><b>${{parseFloat(exemptionammountleft).toFixed(2)}}</b></v-col>
                      <v-col class="text-left my-0 py-0">Exemption amount left for the {{taxyear}} tax year.</v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="2" class="text-right my-0 py-0"><b>${{exemptionamountonform.toFixed(2)}}</b></v-col>
                        <v-col class="text-left my-0 py-0">Exemption amount claimed on the form.</v-col> 
                    </v-row>
                    <v-row class="ma-4">Please fix your exemption amounts and try again.</v-row>

                </v-card-text>
                <v-card-actions>
                    <v-btn @click="exemptiontotaldialog=false">back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>    

        <v-dialog v-model="dialogdeduction" width="800px" persistent>
            <v-card>
                <v-card-title>
                    Unexplained Deductions
                </v-card-title>
                <v-card-text>
                    Some of the deduction explaination fields at the bottom of the form have not been filled in. 
                    These are required fields to make sure that you get the deductions applied.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogdeduction=false">back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>    
        <v-dialog v-model="paydialog" width="800px" persistent>
            <v-card>
                <v-card-title>
                    Pay {{amountpaid}} Tax 
                </v-card-title>
                <v-card-text>
                    <v-row justify="center" class="mx-4 pa-4">
                                <v-col cols="6">
                                    <v-text-field dense label="First Name" placeholder=" " persistent-placeholder v-model="acct.firstname"></v-text-field>
                                    <v-text-field dense label="Last Name" placeholder=" " persistent-placeholder v-model="acct.lastname"></v-text-field>
                                    <v-text-field dense label="Position" placeholder=" " persistent-placeholder v-model="acct.position"></v-text-field>
                                    <v-text-field dense label="Phone" placeholder=" " persistent-placeholder v-model="acct.phone"></v-text-field>
                                    <v-text-field dense label="Email" placeholder=" " persistent-placeholder v-model="acct.email"></v-text-field>
                                </v-col>
                                <v-col> <v-divider vertical></v-divider></v-col>
                                
                                <v-col cols="5" class="mt-1">
                                    <v-row justify="center" class="mb-2"><h3>Terms and Conditions</h3></v-row>
                                    <v-divider></v-divider>
                                    <v-row class="mt-2">
                                        The taxpayer hereby declares that they have read this tax return and certifies it to be correct.<br/><br/>
                                        If the taxpayer is paying online, then the taxpayer agrees to pay a conveniance fee of ${{pf}} that is charged by the third party payment processor. <small><b>(3.03% or a minimum of $1.38, whichever is greater)</b></small>
                                    </v-row>
                                </v-col>
                                
                            </v-row>        
                            <v-row>
                                
                            </v-row>
                            <v-row class="ma-0 pa-0">
                                <v-col cols="1" class="ma-0 pa-0">
                                    <v-row justify="end" class="ma-0 pa-0">
                                    <v-checkbox
                                        v-model="terms"
                                        color="green"
                                        > 
                                        </v-checkbox> 
                                    </v-row>
                                </v-col>
                                <v-col cols="4">
                                    I agree to the tems and conditions for using this form
                                </v-col>
                            </v-row>
                    
                </v-card-text>
                <v-row class="ma-0 pa-0">
                        <v-divider></v-divider>
                    </v-row>
                <v-card-actions>
                  
                    <v-row class="ma-1 pa-2">
                        <v-col>
                            <v-row justify="start">
                                <v-btn width="100" color="green" text @click="paydialog=false">back</v-btn>
                            </v-row>    
                        </v-col>    
                        <v-col>
                            <v-row justify="center" class="mr-2">
                                <v-btn 
                                    @click="paytax('m')" 
                                    v-if="terms" 
                                    width="220" 
                                    height="60"
                                    color="green lighten-3" 
                                    large><b>Pay by Mail or phone</b></v-btn>
                                <v-btn 
                                    v-if="!terms" 
                                    width="200" 
                                    height="60"
                                    color="gray" 
                                    disabled 
                                    small>pay by mail or phone</v-btn>
                            </v-row>    
                        </v-col>
                        <v-col>
                            <v-row rounded justify="end" class="mr-4">
                                <v-sheet 
                                    v-if="terms"  
                                    @click="paytax('o')" 
                                    >
                                    <v-sheet
                                    color="green lighten-3" 
                                    v-if="!tomuch"
                                    width="200" 
                                    height="60"
                                    elevation="4"
                                    rounded
                                    outlined
                                    >
                                        <v-row justify="center" class="mt-2"><b>Pay Online</b></v-row>
                                        <v-row justify="center"><small>(${{pf}} processing fee)</small></v-row>
                                    </v-sheet>

                                    <v-sheet
                                    color="red darken-3" 
                                    v-if="tomuch"
                                    dark
                                    rounded
                                    outlined
                                    width="200" 
                                    height="60"
                                    elevation="4"
                                    >
                                        <v-row class="mt-2" justify="center"><b>Cannot Pay Online</b></v-row>
                                        <v-row justify="center"><small>({{amountpaid}} exceeds $10,000)</small></v-row>
                                    </v-sheet>
                                    
                                </v-sheet>
                                <v-btn v-if="!terms" width="200" height="60" color="gray" disabled small>pay online</v-btn>
                            </v-row>    
                        </v-col>  
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="paydialog2" width="800px" persistent>
            <v-card>
                <v-card-title>
                    <v-row v-if="amountpaid!='$0.00'">Credit of {{amountpaid}} Remaining</v-row>
                    <v-row v-if="amountpaid=='$0.00'">{{amountpaid}} Taxes Due</v-row>
                </v-card-title>
                <v-card-text>
                    <v-row justify="center" class="mx-4 pa-4">
                                <v-col cols="6">
                                    <v-text-field dense label="First Name" placeholder=" " persistent-placeholder v-model="acct.firstname"></v-text-field>
                                    <v-text-field dense label="Last Name" placeholder=" " persistent-placeholder v-model="acct.lastname"></v-text-field>
                                    <v-text-field dense label="Position" placeholder=" " persistent-placeholder v-model="acct.position"></v-text-field>
                                    <v-text-field dense label="Phone" placeholder=" " persistent-placeholder v-model="acct.phone"></v-text-field>
                                    <v-text-field dense label="Email" placeholder=" " persistent-placeholder v-model="acct.email"></v-text-field>
                                </v-col>
                                <v-col> <v-divider vertical></v-divider></v-col>
                                <v-col cols="5" class="mt-1">
                                    <v-row justify="center" class="mb-2"><h3>Terms and Conditions</h3></v-row>
                                    <v-divider></v-divider>
                                    <v-row class="mt-2">
                                        The taxpayer hereby declares that they have read this tax return and certifies it to be correct.<br/><br/>
                                        The taxpayer also certifies that the contact information given on this form (to the left) are correct. <br/><br/>
                                    </v-row>
                                </v-col>
                                
                            </v-row>        
                            <v-row>
                                
                            </v-row>
                            <v-row class="ma-0 pa-0">
                                <v-col cols="1" class="ma-0 pa-0">
                                    <v-row justify="end" class="ma-0 pa-0">
                                    <v-checkbox
                                        v-model="terms"
                                        color="green"
                                        > 
                                        </v-checkbox> 
                                    </v-row>
                                </v-col>
                                <v-col cols="4">
                                    I agree to the tems and conditions for using this form
                                </v-col>
                            </v-row>
                    
                </v-card-text>
                <v-row class="ma-0 pa-0">
                        <v-divider></v-divider>
                    </v-row>
                <v-card-actions>
                  
                    <v-row class="ma-1 pa-2" justify="space-around">
                        <v-btn text @click="paydialog2=false" color="success" width="160">Back</v-btn>
                        <v-divider vertical></v-divider>
                        <v-btn text color="success" width="160"  @click="gohome">Home</v-btn>
                        <v-divider vertical></v-divider>
                        <v-btn text v-if="terms" color="success" @click="paytax('z')" width="160">Submit Zero Tax</v-btn>
                        <v-btn text v-if="!terms" width="160" disabled small>Submit Zero Tax</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="exemptiondialog" width="800px" persistent>
          <v-card>
              <v-card-title>Exemptions</v-card-title>
              <v-card-text>
                  <v-row>
                  <v-col cols="12">
                    <i>
                      Gross reciepts of ${{exemptionammount}} annually are not subject to B & O tax. A return must be submitted reporting Gross Reciepts even if no tax is due.
                    <b>This ${{exemptionammount}} exemption pertains only to B & O Taxes (Retail, Wholesale & Service Catagories). </b>  
                    </i> 
                  </v-col>
                  </v-row>
                  <v-row>
                  <v-col cols="12">
                    <i>
                      You still have <b> ${{exemptionammountleft}} </b> left in exemptions for the {{taxyear}} tax year
                   </i> 
                  </v-col>
                  </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn  text color="green" @click="exemptiondialog=false">Back</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="Deductiondialog" width="800px" persistent>
          <v-card>
              <v-card-title>Deductions</v-card-title>
              <v-card-text>
                    <v-col cols="12">
                        Detailed descriptions of the various tax classifications and allowed deductions for certain business activities are provided in the Bremerton Municipal Code, available on our
                        website at <a href="https://www.bremertonwa.gov/">www.bremertonwa.gov</a>. We are also happy to provide you with a copy of the tax code for any type of activity you are interested in by 
                        calling our office at (360) 473-5311 or (360) 473-5298. The following summarizes the Code pertaining to each business category.
                    </v-col>
                    <v-row class="pl-4">
                    <v-col cols="3">Retail, Wholesale, Service<br/>Utility Taxes<br/>Parking Lot<br/>Amusement Devices<br/>Amusement Games</v-col>
                    <v-col cols="3">
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0348.html#3.48">Chapter 3.48</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0350.html#3.50 ">Chapter 3.50</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0382.html#3.82 ">Chapter 3.82</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0374.html#3.74 ">Chapter 3.74</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0372.html#3.72 ">Chapter 3.72</a><br/>
                    </v-col>
                    <v-col cols="3">Punchboard/Pulltab<br/>Card Rooms<br/>Bingo<br/>Raffles/Other Gambling</v-col>
                    <v-col cols="3">
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0372.html#3.72 ">Chapter 3.72</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0372.html#3.72 ">Chapter 3.72</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0372.html#3.72 ">Chapter 3.72</a><br/>
                            <a target="_blank" href="https://www.codepublishing.com/WA/Bremerton/#!/Bremerton03/Bremerton0372.html#3.72 ">Chapter 3.72</a>
                    </v-col>
                    </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn  text color="green" @click="Deductiondialog=false">Back</v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="penaltydialog" width="800px" persistent>
          <v-card>
              <v-card-title>Late Payment and Underpayment Penalties</v-card-title>
              <v-card-text>
                  <v-col cols="12">
                    In order to avoid penalties, a return must be submitted by the due date listed on the form. A return listing a tax due is subject
                    to the tax due times the Penalty Rate or the Minimum Penalty, wichever is greater. If no tax is due, no penalty is owed.
                    </v-col>
                    
                    <v-row class="pl-4">
                    <v-col cols="12"><h2>Penalty Rate Table</h2></v-col>
                    <v-col cols="4"><b>Days Past Due</b><br/>1-30<br/>31-60<br/>61+</v-col>
                    <v-col cols="4"><b>Penalty Rate</b><br/>9%<br/>19%<br/>29%</v-col>
                    <v-col cols="4"><b>Minimum Penalty</b><br/>$5.00<br/>$5.00<br/>$5.00</v-col>
                    </v-row>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn  text color="green" @click="penaltydialog=false">Back</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="notax" width="600px" persistent>
          <v-card>
              <v-card-title>No Filing Frequency Listed</v-card-title>
              <v-card-text>
                  Without a filing frequency in our database we are unable to calculate Due Dates and taxes owed. Please call (360) 473-5298 if you have any questions.
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn  text color="green" @click="gohome">Home</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <v-row justify="center">
      <v-card
        elevation="8"
        class="ma-8"
        width="1000"
        min-width="1000"
      >
          <v-card-title  class="ma-0 pa-0">
              <v-row justify="center" class="ma-0 pa-0">
                  BREMERTON BUSINESS TAX RETURN INFORMATION
              </v-row>
          </v-card-title>
          <v-card-text  class="ma-0 pa-0">
              <v-row>
                    <v-col cols="12" >
                        <v-row justify="center">
                            <small> (Please be sure name and address are correct. Questions? (360) 473-5298)</small>
                        </v-row>
                    </v-col>
                    <!-- code change to replace "null" string with empty string when value is null -->
                    <v-col v-if="acctdetails.BusName" cols="12">
                        <v-row justify="center" >{{acctdetails.BusName}}</v-row>
                    </v-col>
                    <v-col v-if="acctdetails.LocAdr1" cols="12">
                        <v-row justify="center">{{acctdetails.LocAdr1}}</v-row>
                    </v-col>
                    <v-col  v-if="(acctdetails.LocAdr2 === 'null' || '')" cols="12">
                        <v-row justify="center">
                            {{acctdetails.LocCity}}
                            {{acctdetails.LocState}}
                            {{acctdetails.LocZip}}
                        </v-row>  <!-- if LocAdr2 is 'null', display city, state, zip -->
                    </v-col>
                    <v-col  v-else-if="acctdetails.LocAdr2" cols="12">
                        <v-row justify="center">{{acctdetails.LocAdr2}}</v-row> <!-- if LocAdr2 is not null, display address -->
                        <v-row v-if="(acctdetails.LocAdr3 === 'null' || '')" justify="center">
                            {{acctdetails.LocCity}}
                            {{acctdetails.LocState}}
                            {{acctdetails.LocZip}}
                        </v-row>
                    </v-col>
                    <v-col  v-if="(acctdetails.LocAdr3 === 'null' || '')" cols="12">
                        <!-- <v-row justify="center">
                            {{acctdetails.LocCity}}
                            {{acctdetails.LocState}}
                            {{acctdetails.LocZip}}
                        </v-row> --> 
                    </v-col>
                    <v-col  v-else-if="acctdetails.LocAdr3" cols="12">
                        <v-row justify="center">{{acctdetails.LocAdr3}}</v-row> <!-- if LocAdr2 is not null, display address -->
                        <v-row justify="center">
                            {{acctdetails.LocCity}}
                            {{acctdetails.LocState}}
                            {{acctdetails.LocZip}}
                        </v-row>
                    </v-col>
                    <v-col  cols="12">
                        <!-- <v-row justify="center" v-if="acctdetails.BusName">
                            {{acctdetails.LocCity}}
                            {{acctdetails.LocState}}
                            {{acctdetails.LocZip}}
                            
                        </v-row> -->
                        <v-col class=" my-1">
                            <v-divider></v-divider>
                        </v-col>
                        
                        <v-row justify="center" class="mx-4 my-0 pt-2 px-1">
                            <v-col cols="3" >
                                <v-text-field v-model="acctdetails.AcctNo" readonly outlined label="Account/License No."></v-text-field>
                            </v-col>
                            <v-col cols="1"><v-spacer></v-spacer></v-col>
                            <v-col cols="4" >
                                <v-select :items="taxperiods" @input="taxpinput" v-model="taxperiod" outlined label="Tax Period"></v-select>
                            </v-col>
                            <v-col cols="4" >
                                <v-text-field v-model="datedue" @change="gettaxrate2" outlined readonly label="Date Due"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-sheet outlined class="mx-3 mt=4 py=2" color="grey lighten-3" height="50">
                            <v-row class="ml-2" >
                            <v-col cols="2">
                                <v-row class="mt-1" justify="center"><b> Business Clasification </b></v-row>
                            </v-col>
                            <v-col>
                               <v-row class="mt-1"  justify="center"><b> Gross Amount</b><br/> <small>(In Bremerton)</small></v-row>
                            </v-col>
                            <v-col>
                                <v-row class="mt-1"  justify="center"><b>Deductions</b><br/> <small>***(explain below)***</small></v-row>
                            </v-col>
                            <v-col>
                                <v-row class="mt-1"  justify="center"><b>Exemptions</b></v-row>
                                <v-row class="mt-1"  justify="center" ><small>exemptions remaining</small></v-row>
                                <v-row class="mt-1"  justify="center"><small> for {{taxyear}} ${{exleftnice}}</small></v-row>
                            </v-col>
                            <v-col>
                                <v-row class="mt-1"  justify="center"><b>Taxable Amount</b></v-row>
                            </v-col>
                            <v-col>
                                <v-row class="mt-1"  justify="center"><b>Rate</b></v-row>
                            </v-col>
                            <v-col cols="2">
                                <v-row class="mt-1"  justify="center"><b>Tax Due</b></v-row>
                            </v-col>
                        </v-row>
                        </v-sheet>

                <!--    retail -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">01 Retail <v-spacer></v-spacer>(B&O)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculateretail" v-model="retailg" label="Retail Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculateretail" v-model="retaild" label="Retail Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field :disabled="retailed" class="stringify"  @input="calculateretail" @blur="calculateretail"  v-model="retaile" label="Retail Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="retailta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Retail_01']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="retailtd" label="Retail Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                        
                <!--    wholesale/mfg -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">02 Wholesale<v-spacer></v-spacer>(B&O)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatews" v-model="wholesaleg" label="WS Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatews" v-model="wholesaled" label="WS Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field :disabled="wholesaleed" class="stringify"  @input="calculatews"  v-model="wholesalee" label="WS Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="wholesaleta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Wholesale_02']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="wholesaletd" label="WS Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>

                <!--    Service -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">03 Service <v-spacer></v-spacer>(B&O)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculateservice" v-model="serviceg" label="Service Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculateservice" v-model="serviced" label="Service Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field :disabled="serviceed" class="stringify"  @input="calculateservice"  v-model="servicee" label="Service Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="serviceta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Service_03']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="servicetd" label="Service Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
              <!--    manufacturing -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-5"><v-row justify="start">05 Manufacturing <v-spacer></v-spacer>(B&O)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatemanufacturing" v-model="manufacturingg" label="Man. Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatemanufacturing" v-model="manufacturingd" label="Man. Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field :disabled="manufacturinged" class="stringify"  @input="calculatemanufacturing"  v-model="manufacturinge" label="Man. Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="manufacturingta" label="Man. Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['manufacturing']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="manufacturingtd" label="Man. Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                
                        <v-sheet outlined class="py-2" height="65"  color="teal lighten-4">
                            <v-row class="pt-2" justify="center">
                                <v-col cols="6" >
                                    <v-row class="ml-8">
                                        <v-col cols="12" >
                                            <v-row  justify="start">
                                                B&O taxes are eligible to take an Exemption of up to ${{exemptionammount}} annually.
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" >
                                            <v-row justify="start">
                                                Enter your Exemption in the appropriate exemption box above
                                                <v-btn
                                                    
                                                    max-height="18"
                                                    max-width="10"
                                                    text
                                                    @click="ehelp"
                                                >
                                                    <v-icon >mdi-help-circle-outline</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-divider vertical></v-divider>
                                <v-col cols="5" >
                                    <v-row class="ml-8">
                                        <v-col cols="12">
                                            <v-row justify="start">
                                                 Allowed Deductions for certain business activities are in the
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" >
                                            <v-row justify="start">
                                                
                                                     Bremerton Municipal Code
                                                    <a href="https://www.bremertonwa.gov/">www.bremertonwa.gov.</a> 
                                                    <v-btn
                                                        
                                                        max-height="18"
                                                        max-width="10"
                                                        text
                                                        @click="Deductiondialog=true"
                                                    >
                                                        <v-icon>mdi-help-circle-outline</v-icon>
                                                    </v-btn>
                                                
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-sheet>

                <!--    09 Amusement-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">09 Amusement Device/Games</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculateam" v-model="amusementg" label="Amusement Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculateam" v-model="amusementd" label="Amusement Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         <!--       <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculateam" v-model="amusemente" label="Amusement Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->       <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="amusementta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Amusement_09']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="amusementtd" label="Amusement Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                 <!--    10 punchboard-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">10 Punchboard Pulltab (profit)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatepp" v-model="punchboardg" label="Punchboard Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" filled disabled  @input="calculatepp" v-model="punchboardd" label="Punchboard Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepp" v-model="punchboarde" label="Punchboard Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="punchboardta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['PunchcardProfit_10']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="punchboardtd" label="Punchboard Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    11 punchboard non profit-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">11 Punchboard Pulltab (non-profit)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatepnp" v-model="punchboardnpg" label="Punchboard Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepnp" v-model="punchboardnpd" label="Punchboard Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepnp" v-model="punchboardnpe" label="Punchboard Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="punchboardnpta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['PunchcardNonprofit_11']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="punchboardnptd" label="Punchboard Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    12 Card Rooms (Player Backed)-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">12 Card Rooms (Player Backed)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatecp" v-model="cardplayerg" label="Card Room Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  filled disabled  @input="calculatecp" v-model="cardplayerd" label="Card Room Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatecp" v-model="cardplayere" label="Card Room Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="cardplayerta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['CardroomsPlayer_12']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="cardplayertd" label="Card Room Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                 <!--    13 Card Rooms (House Backed)-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">13 Card Rooms (House Backed)</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatech" v-model="cardhouseg" label="Card Room Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatech" v-model="cardhoused" label="Card Room Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col>
                                    <v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatech" v-model="cardhousee" label="Card Room Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                    </v-row>
                                </v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="cardhouseta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['CardroomsHouse_13']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="cardhousetd" label="Card Room Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    14 Bingo Raffles-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">14 Bingo/Raffles</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatepbr" v-model="bingog" label="Bingo Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepbr" v-model="bingod" label="Bingo Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepbr" v-model="bingoe" label="Bingo Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="bingota" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['BingoRaffle_14']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="bingotd" label="Bingo Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    16 Other Gambling-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">16 Other Gambling</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatepog" v-model="gambleg" label="Gambling Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepog" v-model="gambled" label="Gambling Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepog" v-model="gamblee" label="Gambling Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="gambleta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['OtherGambling_16']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="gambletd" label="Gambling Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    21 Parking lot-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">21 Parking Lot</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatepark" v-model="parkg" label="Parking Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" filled disabled  @input="calculatepark" v-model="parkd" label="Parking Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatepark" v-model="parke" label="Parking Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="parkta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Parkinglot_21']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="parktd" label="Parking Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    23 electric -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">23 Electric</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculateele" v-model="electricg" label="Electric Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculateele" v-model="electricd" label="Electric Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatetge" v-model="tgee" label="Electric Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="electricta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Telephone_25']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="electrictd" label="Electric Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                        <!--    24 gas -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">24 Gas</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculategas" v-model="gasg" label="Gas Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculategas" v-model="gasd" label="Gas Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatetge" v-model="tgee" label="Gas Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="gasta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Telephone_25']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="gastd" label="Gas Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                        <!-- 25 Telephone -->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">25 Telephone</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatetel" v-model="telephoneg" label="Telephone Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatetel" v-model="telephoned" label="Telephone Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatetge" v-model="tgee" label="TGE Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="telephoneta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['Telephone_25']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="telephonetd" label="Telephone Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    26 Garage Utility-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">26 Garbage Utility</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculategarb" v-model="garbageg" label="Garbage Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculategarb" v-model="garbaged" label="Garbage Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculategarb" v-model="garbagee" label="Garbage Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="garbageta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['GarbageUtility_26']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="garbagetd" label="Garbage Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                <!--    29 Cable Utility-->
                        <v-sheet outlined :class="sclass" :height="sheight">
                            <v-row class="ml-2" >
                                <v-col cols="2" class="px-8"><v-row justify="start">27 Cable Utility</v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"   @input="calculatecable" v-model="cableg" label="Cable Gross" placeholder=" " persistent-placeholder outlined dense ></v-text-field>    
                                </v-row></v-col>
                                <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatecable" v-model="cabled" label="Cable Deduction" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                        <!--         <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify"  @input="calculatecable" v-model="cablee" label="Cable Exemption" placeholder=" " persistent-placeholder outlined dense ></v-text-field>
                                </v-row></v-col>
                         -->        <v-col><v-row justify="center" class="px-1">
                                    <v-text-field class="stringify" :background-color="bgc1" v-model="cableta" label="Taxable Amount" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                                <v-col><v-row justify="center"><b>{{taxrates['CableUtility_29']}}</b></v-row></v-col>
                                <v-col cols="2"><v-row justify="center" class="px-4">
                                    <v-text-field class="stringify" :background-color="bgc2" reverse v-model="cabletd" label="Cable Tax Due" placeholder=" " persistent-placeholder outlined dense readonly></v-text-field>
                                </v-row></v-col>
                            </v-row>
                        </v-sheet>
                
                <!--    deductions-->
                        <v-sheet outlined class="mx-3 my-0 pa-0" height="35">
                            <v-row >
                                <v-col  cols="9">
                                    <v-sheet color="red lighten-4">
                                        <v-row  class="my-1 py-0" justify="center"><b>***Unexplained deductions will not be allowed***</b></v-row>
                                    </v-sheet>
                                </v-col>
                                <v-col  cols="2">
                                    <b>Total Tax Due</b>
                                </v-col>
                                <v-col class="my-0 py-0 ml-0 pl-0 pr-9 mr-0" cols="1">
                                    <v-row justify="end" align="start">
                                       {{ttd}} 
                                    </v-row>
                                </v-col>   
                            </v-row>
                        </v-sheet>
                        <v-row class="mx-3 my-0 pa-0">
                            <v-col cols="9">
                                <v-sheet  class="ma-0 pa-0" >
                                    <v-text-field @input="deductionexplanations" v-model="retailex" v-if="retaild != ''" :label="'$' + retaild + ' Retail Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="wholesaleex" v-if="wholesaled != ''" :label="'$' + wholesaled + ' Wholesale Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="serviceex" v-if="serviced != ''" :label="'$' + serviced + ' Service Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="manufacturingex" v-if="manufacturingd != ''" :label="'$' + manufacturingd + ' Manufacturer Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="amusementex" v-if="amusementd != ''" :label="'$' + amusementd + ' Amusement Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="punchboardnpex" v-if="punchboardnpd != ''" :label="'$' + punchboardnpd + ' Punchboard Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="cardhouseex" v-if="cardhoused != ''" :label="'$' + cardhoused + ' Card Room Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="bingoex" v-if="bingod != ''" :label="'$' + bingod + ' Bingo/Raffle Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="gambleex" v-if="gambled != ''" :label="'$' + gambled + ' Other Gambling Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="garbageex" v-if="garbaged != ''" :label="'$' + garbaged + ' Garbage Utility Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="cableex" v-if="cabled != ''" :label="'$' + cabled + ' Cable Utility Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="telex" v-if="telephoned != ''" :label="'$' + telephoned + ' Telephone Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="gasex" v-if="gasd != ''" :label="'$' + gasd + ' Gas Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                    <v-text-field @input="deductionexplanations" v-model="eleex" v-if="electricd != ''" :label="'$' + electricd + ' Electric Deduction Explanation'" background-color="amber lighten-5" dense outlined placeholder=" " persistent-placeholder ></v-text-field>
                                         
                                </v-sheet>
                            </v-col>
                            <v-col  class="pr-6" cols="3">
                                <v-row justify="end">
                                    <v-btn
                                        class="mb-0 pa-0"
                                        max-height="18"
                                        max-width="10"
                                        text
                                        @click="phelp"
                                    >
                                    <v-icon class="mb-0 pa-0">mdi-help-circle-outline</v-icon>
                                </v-btn>Penalty Due {{penalty}} 
                                </v-row>
                                <v-row justify="end">
                                    Tax and Penalty {{ttap}}
                                </v-row>
                                <v-row justify="end">
                                    Balance Forward ${{balanceforward}}
                                </v-row>
                                <v-row>
                                    <v-divider class="ml-12"></v-divider>
                                </v-row>
                                <!-- There are three options here, 
                                                                    1) File a zero balance 
                                                                    2) pay all taxes and penalties 
                                                                    3) pay just the balance forward 
                                                                    4) money the city owes has to be zerod out-->
                                <!-- file a zero balance -->
                                <v-row v-if="amountpaid == '$0.00'" justify="end" class="reverse pt-2">
                                    <v-btn 
                                        v-if="check > 0"
                                        color="amber lighten-2"
                                        light
                                        elevation="12"
                                        min-height="60"
                                        min-width="170"
                                        @click="gopdf2"
                                        class="mb-4"
                                        >
                                        File a Zero<br/>Balance Return<br/>
                                    (NEXT)
                                    </v-btn>
                                </v-row>
                                
                                <!-- amount paid is less than zero  -->
                                <v-row v-if="amountpaid != '$0.00' && amountpaid.includes('-')" justify="end" class="reverse pt-2">
                                   <v-btn 
                                        style="white-space: normal;"
                                        color="green lighten-4"
                                        elevation="12"
                                        min-height="90"
                                        min-width="170"
                                        @click="gopdf2"
                                        class="mb-4"
                                        x-small
                                        >
                                    Credit Balance Forward Exceeds Total Tax and Penalty.<br/>
                                    Your remaining credit balance is {{amountpaid}}<br/>
                                    Click Here to Submit this form.
                                    </v-btn>
                                </v-row>

                                <!-- amount paid is greater than zero  -->
                                <v-row v-if="amountpaid != '$0.00' && amountpaid.indexOf('-') < 0" justify="end" class="reverse pt-2">
                                    
                                    <!-- add a button for paying just the balance forward -->
                                    <v-btn 
                                        style="white-space: normal;"
                                        color="green lighten-4"
                                        elevation="12"
                                        min-height="60"
                                        min-width="180"
                                        @click="gopdf"
                                        class="mb-4"
                                        >
                                    Pay Tax {{amountpaid}} <br/>
                                    (NEXT)
                                    </v-btn>
                                    
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
              </v-row>
          </v-card-text>
        </v-card>
    </v-row>
    <v-row>
        <v-col>
            <v-row justify="center"><pdf ref="pdf"></pdf></v-row>
        </v-col>
    </v-row>
    
  </v-app>
</template>

<script>
import axios from "axios";
import pdf from "../components/pdf";

export default{
 data: () => ({
     sclass:"mx-3 pt-6",
     sheight:"65",
     retailgh:'',
     payinput:false,
     terms:false,
     bgc1:"blue lighten-5",
     bgc2:"teal lighten-5",
    iframe:{src:'https://google.com'},
    ifs:'',
    printpdfcolor:"green lighten-4",
    notax:false,
    checkforzero:false,
    docno:0,
    check:1,
    tomuch:false,
    exceedslimitsdialog:false,
    payonlineinfodialog:false,
    exemptiondialog:false,
    exemptiontotaldialog:false,
    Deductiondialog:false,
    dialogdeduction:false,
    dialogemptytax:false,
    dialogdownloadpdf:false,
    dialogdownloadpdf2:false,
    penaltydialog:false,
    paydialog:false,
    paydialog2:false,
    payonlinedialog:false,
    snackbar:false,
    snackbarm:'',
    acct:[],
    acctdetails:[],
    taxperiod:'',
    taxyear:'',
    pf:0,
    taxperiods:[],
    taxrates:[],
    datedue:'',
    retailg:'',
    retaild:'',
    retaile:'',
    retailed:false,
    retailta:'',
    retailtd:'',
    retailex:'',
    wholesaleg:'',
    wholesaled:'',
    wholesalee:'',
    wholesaleed:false,
    wholesaleta:'',
    wholesaletd:'',
    wholesaleex:'',
    serviceg:'',
    serviced:'',
    servicee:'',
    serviceed:false,
    serviceta:'',
    servicetd:'',
    serviceex:'',
    manufacturingg:'',
    manufacturingd:'',
    manufacturinge:'',
    manufacturinged:false,
    manufacturingta:'',
    manufacturingtd:'',
    manufacturingex:'',
    amusementg:'',
    amusementd:'',
    amusemente:'',
    amusementta:'',
    amusementtd:'',
    amusementex:'',
    punchboardg:'',
    punchboardd:'',
    punchboarde:'',
    punchboardta:'',
    punchboardtd:'',
    punchboardex:'',
    punchboardnpg:'',
    punchboardnpd:'',
    punchboardnpe:'',
    punchboardnpta:'',
    punchboardnptd:'',
    punchboardnpex:'',
    cardplayerg:'',
    cardplayerd:'',
    cardplayere:'',
    cardplayerta:'',
    cardplayertd:'',
    cardplayerex:'',
    cardhouseg:'',
    cardhoused:'',
    cardhousee:'',
    cardhouseta:'',
    cardhousetd:'',
    cardhouseex:'',
    bingog:'',
    bingod:'',
    bingoe:'',
    bingota:'',
    bingotd:'',
    bingoex:'',
    gambleg:'',
    gambled:'',
    gamblee:'',
    gambleta:'',
    gambletd:'',
    gambleex:'',
    parkg:'',
    parkd:'',
    parke:'',
    parkta:'',
    parktd:'',
    parkex:'',
    garbageg:'',
    garbaged:'',
    garbagee:'',
    garbageta:'',
    garbagetd:'',
    garbageex:'',
    cableg:'',
    cabled:'',
    cablee:'',
    cableta:'',
    cabletd:'',
    cableex:'',
    tgeg:'',
    tged:'',
    tgee:'',
    tgeta:'',
    tgetd:'',
    tgeex:'',
    telephoneg:'',
    telephoned:'',
    telephonee:'',
    telephoneta:'',
    telephonetd:'',
    telex:'',
    gasg:'',
    gasd:'',
    gase:'',
    gasta:'',
    gastd:'',
    gasex:'',
    electricg:'',
    electricd:'',
    electrice:'',
    electricta:'',
    electrictd:'',
    eleex:'',
    ttd:'$0.00',
    exemptionammount:'',
    exemptionammountused:'0.00',
    exemptionammountleft:'0.00',
    exleftnice:'',
    exemptionamountonform:0,
    exemptionammountusedA:[],
    deductions:[],
    explanations:[],
    penalty:'$0.00',
    ttap:'$0.00',
    balanceforward:'$0.00',
    amountpaid:'$0.00',
    }),
     components: {
        pdf,
    },
    methods:{
        checkiframe(){
            this.payonlinedialog=false;
        },
        checkiframeinfo(){
            this.payonlineinfodialog=true;
            
        },
        printpdf(){
            this.printpdfcolor="green darken-2"
            this.$refs.pdf.getdata();
        },
        steptwo(){
            this.dialogdownloadpdf=false;
            this.payonlinedialog=true;
        },
        //checking to see if there is a white space, null in the deduction explanation
        validationCheck () {
            
            //check for whitespace or null
            let inputCheck = this.explanations.some(obj => obj.explanation === null || obj.explanation.trim() === '');
           
            if(inputCheck || this.explanations.length != this.deductions.length) {
                this.dialogdeduction=true;
            }
            else {
                //calculate interest
                let x=this.amountpaid.substring(1)*.0303;
        
                         this.paydialog=true; 
                         if(x>1.5){
                             this.pf=x.toFixed(2);
                         }else{
                             this.pf=1.38;
                         }
            }
            
        },
      gopdf(){
          let a=0;
          let b=0;
          let c=0;
          let d=0;

          if(this.retaile){a=parseFloat(this.retaile)}
          if(this.wholesalee){b=parseFloat(this.wholesalee)}
          if(this.servicee){c=parseFloat(this.servicee)}
          if(this.manufacturinge){d=parseFloat(this.manufacturinge)}
          let t=parseFloat(this.exemptionammountleft);
          if(a+b+c+d>t){
              this.exemptionamountonform=a+b+c+d;
              this.exemptiontotaldialog = true;
       }
        this.validationCheck();
      },
      gopdf2(){

 if(this.checkforzero == true){
            let a=0;
            let b=0;
            let c=0;
            let d=0;


            if(this.retaile){a=parseFloat(this.retaile)}
            if(this.wholesalee){b=parseFloat(this.wholesalee)}
            if(this.servicee){c=parseFloat(this.servicee)}
            if(this.manufacturinge){d=parseFloat(this.manufacturinge)}
            let t=parseFloat(this.exemptionammountleft);

            if(a+b+c+d>t){
                this.exemptionamountonform=a+b+c+d;
                this.exemptiontotaldialog = true;
            }
            else{
                let a=this.deductions.length;
                let b=this.explanations.length;
                if(a==b){
                    this.paydialog2=true; 
                }else{
                    this.dialogdeduction=true;
                }
            }
        }else{
            this.dialogemptytax=true;
        }
    },
    
      getexemption(){
            let thisvue=this;
            let url = this.$store.state.url;
            let yrA = this.taxperiod.split(" ");
            let yr = yrA[1];
            const params = new URLSearchParams();
            params.append('ty', 'getexemptionrate');
            params.append('yr', yr);
            
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){ 
                thisvue.exemptionammount=response.data;
                thisvue.getexemptionused();
            });
        },
        getexemptionused(){
            this.exemptionammountused="0.00";
            this.exemptionammountleft=this.exemptionammount;
            let thisvue=this;
            let url = this.$store.state.url;
            let y=this.taxperiod;
            let yA=y.split(" ");
            let yr=yA[1];
            const params = new URLSearchParams();
            params.append('ty', 'getexemptionused');
            params.append('acntno', this.acctdetails.AcctNo);
            //params.append('acntDb',this.acctdetails.AcctDb);
            params.append('yr',yr);
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){ 
                let exused=response.data;
                thisvue.exemptionammountused=exused;
                let ex=parseFloat(thisvue.exemptionammount);
                let exleft=(ex-exused);
                exleft=(parseInt(exleft*100));
                exleft=parseFloat(exleft/100);
                //thisvue.exleftnice=exleft.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                thisvue.exleftnice=exleft.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                thisvue.exemptionammountleft= exleft;
            }); 
        },
      gettaxrate2(){
        let thisvue=this;
        let url = this.$store.state.url;
        let yrA = this.taxperiod.split(" ");
        let yr = yrA[1];
        const params = new URLSearchParams();
        params.append('ty', 'gettaxrate');
        params.append('yr', yr);
         
        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
            if(response.data == "bad"){
                window.console.log("bad");
            }else{
                thisvue.taxrates = response.data;
            }
        })
        .then(function(){
            thisvue.calculateall();
            thisvue.getexemption();
        });
      },
        paytax(x){

            if(this.tomuch==true && x=='o'){
                this.exceedslimitsdialog=true;
                return null;
            }
            /////////////////////////pay the tax amount
            let pay=[];
            let exemptions=[];
            let retailpay={id:'retail',retailg:this.retailg,retaild:this.retaild,retaile:this.retaile,retailta:this.retailta,retailtd:this.retailtd};
            let wholesalepay={id:'wholesale',wholesaleg:this.wholesaleg,wholesaled:this.wholesaled,wholesalee:this.wholesalee,wholesaleta:this.wholesaleta,wholesaletd:this.wholesaletd};
            let servicepay={id:'service',serviceg:this.serviceg,serviced:this.serviced,servicee:this.servicee,serviceta:this.serviceta,servicetd:this.servicetd};
            let manufpay={id:'manufacturing',manufacturingg:this.manufacturingg,manufacturingd:this.manufacturingd,manufacturinge:this.manufacturinge,manufacturingta:this.manufacturingta,manufacturingtd:this.manufacturingtd};

            let Amusementpay={id:'amusement',amusementg:this.amusementg,amusementd:this.amusementd,amusemente:this.amusemente,amusementta:this.amusementta,amusementtd:this.amusementtd};
            let punchboardpay={id:'punchboard',punchboardg:this.punchboardg,punchboardd:this.punchboardd,punchboarde:this.punchboarde,punchboardta:this.punchboardta,punchboardtd:this.punchboardtd};
            let punchboardnppay={id:'punchboardnp',punchboardnpg:this.punchboardnpg,punchboardnpd:this.punchboardnpd,punchboardnpe:this.punchboardnpe,punchboardnpta:this.punchboardnpta,punchboardnptd:this.punchboardnptd};

            let cardplayerpay={id:'cardplayer',cardplayerg:this.cardplayerg,cardplayerd:this.cardplayerd,cardplayere:this.cardplayere,cardplayerta:this.cardplayerta,cardplayertd:this.cardplayertd};
            let cardhousepay={id:'cardhouse',cardhouseg:this.cardhouseg,cardhoused:this.cardhoused,cardhousee:this.cardhousee,cardhouseta:this.cardhouseta,cardhousetd:this.cardhousetd};
            let bingopay={id:'bingo',bingog:this.bingog,bingod:this.bingod,bingoe:this.bingoe,bingota:this.bingota,bingotd:this.bingotd};

            let gamblepay={id:'gamble',gambleg:this.gambleg,gambled:this.gambled,gamblee:this.gamblee,gambleta:this.gambleta,gambletd:this.gambletd};
            let parkpay={id:'park',parkg:this.parkg,parkd:this.parkd,parke:this.parke,parkta:this.parkta,parktd:this.parktd};
            let garbagepay={id:'garbage',garbageg:this.garbageg,garbaged:this.garbaged,garbagee:this.garbagee,garbageta:this.garbageta,garbagetd:this.garbagetd};

            let cablepay={id:'cable',cableg:this.cableg,cabled:this.cabled,cablee:this.cablee,cableta:this.cableta,cabletd:this.cabletd};
            let telpay={id:'telephone',telephoneg:this.telephoneg,telephoned:this.telephoned,telephonee:this.telephonee,telephoneta:this.telephoneta,telephonetd:this.telephonetd};
            let gaspay={id:'gas',gasg:this.gasg,gasd:this.gasd,gase:this.gase,gasta:this.gasta,gastd:this.gastd};
            let elepay={id:'electric',electricg:this.electricg,electricd:this.electricd,electrice:this.electrice,electricta:this.electricta,electrictd:this.electrictd};

            let typeofpayment="";
            if(x=="m"){
                typeofpayment="Mail"
            }else{
                typeofpayment="Online"
            }

            pay[0]=retailpay;
            pay[1]=wholesalepay;
            pay[2]=servicepay;
            pay[3]=manufpay;
            pay[4]=Amusementpay;
            pay[5]=punchboardpay;
            pay[6]=punchboardnppay;
            pay[7]=cardplayerpay;
            pay[8]=cardhousepay;
            pay[9]=bingopay;
            pay[10]=gamblepay;
            pay[11]=parkpay;
            pay[12]=garbagepay;
            pay[13]=cablepay;
            pay[14]=telpay;
            pay[15]=gaspay;
            pay[16]=elepay;

            exemptions[0]=this.retaile;
            exemptions[1]=this.wholesalee;
            exemptions[2]=this.servicee;
            exemptions[3]=this.manufacturinge;

            let p=JSON.stringify(pay);
            let e=JSON.stringify(this.explanations);
            let d=JSON.stringify(this.deductions);
            let ex=JSON.stringify(exemptions);

            if(this.acct.email){this.acct.email=this.acct.email.trim();}
            let thisvue = this;
            let url = this.$store.state.url;
            const params = new URLSearchParams();
            params.append('ty', 'payonline');
            params.append('acntno' , this.acct.AcctNo);
            params.append('acntDb' , this.acct.AcctDb);
            params.append('fn' , this.acct.firstname);
            params.append('ln' , this.acct.lastname);
            params.append('po' , this.acct.position);
            params.append('ph' , this.acct.phone);
            params.append('em' , this.acct.email);
            params.append('items' , p);
            params.append('prikey' , this.acct.fprikey);

            params.append('totaltax' , this.ttd);
            params.append('penalty' , this.penalty);
            params.append('balanceforward' , this.balanceforward);
            params.append('grandtotal' , this.amountpaid);

            // setAmountPaid
            thisvue.$store.commit('setAmountPaid',this.amountpaid); 

            params.append('deductions' , d);
            params.append('explanations',e);
            params.append('exemptions',ex);
            params.append('datedue',this.datedue);
            params.append('taxperiod',this.taxperiod);
            params.append('exemptionrate',this.exemptionammount);
            params.append('typeofpayment',typeofpayment);


            axios({
                method:"post",
                url:url,
                data:params
            })
            
            .then(function(response){
        
                if(response.data == "bad"){
                    thisvue.payinput=true;
                }else{
                    let pt = response.data.toString();    
                    thisvue.$store.commit('setpaytime',pt);  
                }
            }).then(function(){
                ////////////////////////////////////////insert exemptions into the exemption table;
                thisvue.insertexemptions(x);
            });

        },
        insertexemptions(x){
            ///////////////////////////////////////insert exemptions into the exemption table;
            let re=0;
            let wholesalee=0;
            let se=0;

            if(this.retaile){re=parseFloat(this.retaile);}
            if(this.wholesalee){wholesalee=parseFloat(this.wholesalee);}
            if(this.servicee){se=parseFloat(this.servicee);}

            let ex=re+wholesalee+se;
            let yr=this.taxperiod;
            let yrA=yr.split(" ");
            let taxyear=yrA[1];
            let note = taxyear+" $"+ex;
            let acntno = this.acct.AcctNo;
            let acntDb = this.acct.AcctDb;
    
            let thisvue = this;
            let url = this.$store.state.url;
            const params = new URLSearchParams();
            params.append('ty', 'insertex');
            params.append('note' , note);
            params.append('acntno', acntno);
            params.append('acntDb', acntDb);
            
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
                if(response.data == "good"){
                    if(x=='m'){
                        ////////////////////pay by mail
                        thisvue.$router.replace({ name: "mailpdf" });
                    }
                    if(x=='o'){
                        ////////////////////pay online
                        thisvue.payonline();
                    }
                    if(x=='z'){
                        ////////////////////pay zero tax
                        ////////////////////get document number
                        const params = new URLSearchParams();
                        params.append('ty', 'getdocno');
                        params.append('note' , thisvue.$store.state.paytime);
                        params.append('acntno', acntno);
                        axios({
                            method:"post",
                            url:url,
                            data:params
                        })
                        .then(function(response){
                        thisvue.docno=response.data; 
                        //open dialog
                        thisvue.dialogdownloadpdf2=true;
                        });
                    }
                }else{
                    window.console.log("it didnt work");
                }
            });
        },
        payonline(){
            this.dialogdownloadpdf=true;
            let phone = this.acct.phone.replace(/\D/g,'');
            let phone1=phone.substr(0,3);
            let phone2=phone.substr(3,3);
            let phone3=phone.substr(6,4);
            let email = this.acct.email.trim();
            let bill = this.amountpaid.substring(1);
            this.iframe.src=this.$store.state.url2+"?iframe=true&header.accountNumber="+
            this.acct.AcctNo+"&header.paymentTypeCode=TAX&step=2&customer.email="+email+"&header.amount="+bill+
            "&customer.dayPhone.npa="+phone1+"&customer.dayPhone.nxx="+phone2+"&customer.dayPhone.did="+phone3+
            "&customer.firstName="+this.acct.firstname+"&customer.lastName="+this.acct.lastname+"&customer.address.zipCode="+this.acct.LocZip;
            this.ifs=this.iframe.src.replace("iframe=true&","");
        },
        ehelp(){
            /////////////////////exemption help dialog
            this.exemptiondialog=true;
        },
        phelp(){
            /////////////////////penalty help dialog
            this.penaltydialog=true;
        },
        amountp(){
            ///////////////////////grand total amount paid
            let ttdA=this.ttd.split("$");
            let penA=this.penalty.split("$");
            let bfA=this.balanceforward;

            let t=parseFloat(ttdA[1]);
            let p=parseFloat(penA[1]);
            let b=parseFloat(bfA);
            let ttl=(t+p+b).toFixed(2);
            this.amountpaid="$"+ttl.toString();
            if(ttl>9999){
                this.tomuch=true;
            }else{
                this.tomuch=false;
            }
            
        },
        tapd(){
            ////////////////////////////tax and penalty due
            let ttdA=this.ttd.split("$");
            let penA=this.penalty.split("$");
            let t=parseFloat(ttdA[1]);
            let p=parseFloat(penA[1]);
            let ttl=(t+p).toFixed(2);
            this.ttap="$"+ttl.toString();
            this.amountp();
        },
        penaltydue(){
            
            let t = this.datedue;
   
            //check if the due date is on a weekend and add one day if it is
            let tcheck = new Date(t);
            let tcheck2 = tcheck.getDay();
            if (tcheck2 == 6 ){
                tcheck.setDate(tcheck.getDate() + 2);
                t=tcheck;
                this.datedue = tcheck.toDateString();
                
            }
            if (tcheck2 == 0 ){
                tcheck.setDate(tcheck.getDate() + 1);
                t=tcheck;
                this.datedue = tcheck.toDateString();
            }
    

           let td=this.ttd;
           let tdA=td.split("$");
           td=tdA[1];
           
           let dd=Date.parse(t);    //date due
           let dn=Date.now();       //date now
           let dl=Math.floor((dn-dd)/86400000);             // days late

               if(dl>0 && dl<31){
                    let penalty=parseFloat(td) * .09;
                    if(penalty > 5){penalty="$"+penalty.toFixed(2).toString();}
                    else{penalty="$5.00";}
                    if(td ==0){penalty="$0.00";}
                    this.penalty=penalty;
               }
               if(dl>30 && dl<61){
                    let penalty=parseFloat(td) * .19;
                    if(penalty > 5){penalty="$"+penalty.toFixed(2).toString();}
                    else{penalty="$5.00";}
                    if(td ==0){penalty="$0.00";}
                    this.penalty=penalty;
               }
               if(dl>60){
                    let penalty=parseFloat(td) * .29;
                    if(penalty > 5){penalty="$"+penalty.toFixed(2).toString();}
                    else{penalty="$5.00";}
                    if(td ==0){penalty="$0.00";}
                    this.penalty=penalty;
               }
               if(dl<1){
                   this.penalty="$0.00";
               }
            this.tapd(); 
        },
        ttdue(){
            this.checkforzero=false;
            if(this.retailg || this.wholesaleg || this.serviceg || this.manufacturingg || this.amusementg || this.punchboardg || this.punchboardnpg || this.cardplayerg || this.cardhouseg || this.bingog || this.gambleg || this.parkg || this.garbageg || this.cableg || this.telephoneg || this.electricg || this.gasg){this.checkforzero=true;}
           
           
            let t1=this.retailtd;if(!t1){t1=0;}
            let t2=this.wholesaletd;if(!t2){t2=0;}
            let t3=this.servicetd;if(!t3){t3=0;}
            let tm=this.manufacturingtd;if(!tm){tm=0;}
            let t4=this.amusementtd;if(!t4){t4=0;}
            let t5=this.punchboardtd;if(!t5){t5=0;}
            let t6=this.punchboardnptd;if(!t6){t6=0;}
            let t7=this.cardplayertd;if(!t7){t7=0;}
            let t8=this.cardhousetd;if(!t8){t8=0;}
            let t9=this.bingotd;if(!t9){t9=0;}
            let t10=this.gambletd;if(!t10){t10=0;}
            let t11=this.parktd;if(!t11){t11=0;}
            let t12=this.garbagetd;if(!t12){t12=0;}
            let t13=this.cabletd;if(!t13){t13=0;}
            let t14=this.telephonetd;if(!t14){t14=0;}
            let t15=this.electrictd;if(!t15){t15=0;}
            let t16=this.gastd;if(!t16){t16=0;}
            let td=parseFloat(t1)+parseFloat(t2)+parseFloat(t3)+parseFloat(tm)+parseFloat(t4)+parseFloat(t5)+parseFloat(t6)+parseFloat(t7)+parseFloat(t8)+parseFloat(t9)+parseFloat(t10)+parseFloat(t11)+parseFloat(t12)+parseFloat(t13)+parseFloat(t14)+parseFloat(t15)+parseFloat(t16);
            td=td.toFixed(2);
            
            this.ttd='$'+td.toString();
            this.deductionsitemized();
            this.deductionexplanations();
            this.penaltydue();
        },
        deductionsitemized(){
             this.deductions=[];
            if(this.retaild){this.deductions.push({name:'Retail',amount:this.retaild});}
            if(this.wholesaled){this.deductions.push({name:'Wholesale',amount:this.wholesaled});}
            if(this.serviced){this.deductions.push({name:'Service',amount:this.serviced});}
            if(this.manufacturingd){this.deductions.push({name:'Manufacturing',amount:this.manufacturingd});}
            if(this.amusementd){this.deductions.push({name:'Amusement',amount:this.amusementd});}
            if(this.punchboardnpd){this.deductions.push({name:'Punchboard',amount:this.punchboardnpd});}
            if(this.cardhoused){this.deductions.push({name:'Card Room',amount:this.cardhoused});}
            if(this.bingod){this.deductions.push({name:'Bingo/Rafle',amount:this.bingod});}
            if(this.gambled){this.deductions.push({name:'Gambling',amount:this.gambled});}
            if(this.garbaged){this.deductions.push({name:'Garbage',amount:this.garbaged});}
            if(this.cabled){this.deductions.push({name:'Cable',amount:this.cabled});}
            if(this.telephoned){this.deductions.push({name:'Telephone',amount:this.telephoned});}
            if(this.electricd){this.deductions.push({name:'Gas',amount:this.electricd});}
            if(this.gasd){this.deductions.push({name:'Electric',amount:this.gasd});}
        },
        deductionexplanations(){
             this.explanations=[];
            if(this.retailex){this.explanations.push({name:'Retail',explanation:this.retailex});}
            if(this.wholesaleex){this.explanations.push({name:'Wholesale',explanation:this.wholesaleex});}
            if(this.serviceex){this.explanations.push({name:'Service',explanation:this.serviceex});}
            if(this.manufacturingex){this.explanations.push({name:'Manufacturing',explanation:this.manufacturingex});}
            if(this.amusementex){this.explanations.push({name:'Amusement',explanation:this.amusementex});}
            if(this.punchboardnpex){this.explanations.push({name:'Punchboard',explanation:this.punchboardnpex});}
            if(this.cardhouseex){this.explanations.push({name:'Card Room',explanation:this.cardhouseex});}
            if(this.bingoex){this.explanations.push({name:'Bingo/Rafle',explanation:this.bingoex});}
            if(this.gambleex){this.explanations.push({name:'Gambling',explanation:this.gambleex});}
            if(this.garbageex){this.explanations.push({name:'Garbage',explanation:this.garbageex});}
            if(this.cableex){this.explanations.push({name:'Cable',explanation:this.cableex});}
            if(this.telex){this.explanations.push({name:'Telephone',explanation:this.telex});}
            if(this.gasex){this.explanations.push({name:'Gas',explanation:this.gasex});}
            if(this.eleex){this.explanations.push({name:'Electric',explanation:this.eleex});}
        },
        calculateall(){
            this.calculateretail();
            this.calculatews();
            this.calculateservice();
            this.calculatemanufacturing();
            this.calculateam();
            this.calculatepp();
            this.calculatepnp();
            this.calculatecp();
            this.calculatech();
            this.calculatepbr();
            this.calculatepog();
            this.calculatepark();
            this.calculategarb();
            this.calculatecable();
            this.calculatetel();
            this.calculategas();
            this.calculateele();
        },
        checkexemptionoverages(){
         //check if enough exemption is left to use
            let e=this.exemptionammountleft;
            let sbm = "";
            let x=0;
            //retail
            let er=parseFloat(this.retaile);if(!er){er=0;}
            let gr=parseFloat(this.retailg);if(!gr){gr=0;}
            let dr=parseFloat(this.retaild);if(!dr){dr=0;}
            //wholesale
            let ew=parseFloat(this.wholesalee);if(!ew){ew=0;}
            let gw=parseFloat(this.wholesaleg);if(!gw){gw=0;}
            let dw=parseFloat(this.wholesaled);if(!dw){dw=0;}
            //service
            let es=parseFloat(this.servicee);if(!es){es=0;}
            let gs=parseFloat(this.serviceg);if(!gs){gs=0;}
            let ds=parseFloat(this.serviced);if(!ds){ds=0;}
            //manufacturing
            let em=parseFloat(this.manufacturinge);if(!em){em=0;}
            let gm=parseFloat(this.manufacturingg);if(!gm){gm=0;}
            let dm=parseFloat(this.manufacturingd);if(!dm){dm=0;}
            //fix the exemption overages
            if(er>e){sbm="Your retail exemption is greater than your exemption amount left.";x=1}
            if(ew>e){sbm="Your wholesale exemption is greater than your exemption amount left.";x=1}
            if(es>e){sbm="Your service exemption is greater than your exemption amount left.";x=1}
            if(em>e){sbm="Your manufacturing exemption is greater than your exemption amount left.";x=1}
            if((er + ew + es + em)>e && x==0){sbm="Your total exemptions used are greater than your exemption amount left.";x=1;}

            //check if we should reduce the exemption used
            if(gr<dr+er && er>0){er=gr-dr;if(er<0){er=0;}er=er.toFixed(2);this.retaile=er;sbm="Your retail exemption has been reduced to "+er+" to help you save your remaining exemption amount";}
            if(gw<dw+ew && ew>0){ew=gw-dw;if(ew<0){ew=0;}ew=ew.toFixed(2);this.wholesalee=ew;sbm="Your Wholesale exemption has been reduced to "+ew+" to help you save your remaining exemption amount";}
            if(gs<ds+es && es>0){es=gs-ds;if(es<0){es=0;}es=es.toFixed(2);this.servicee=es;sbm="Your service exemption has been reduced to "+es+" to help you save your remaining exemption amount";}
            if(gm<dm+em && em>0){em=gm-dm;if(em<0){em=0;}em=em.toFixed(2);this.retailm=em;sbm="Your manufacturing exemption has been reduced to "+em+" to help you save your remaining exemption amount";}

            if(sbm != "")
            {
                this.snackbarm=sbm;
                this.snackbar=true;
            }
        },
        calculateretail(){
            
            let fg=this.fixfloat(this.retailg);
                if(fg != this.retailg){
                    setTimeout(() => {
                        this.retailg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.retaild);
                if(fd != this.retaild){
                    setTimeout(() => {
                        this.retaild = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.retaile);
                if(fe != this.retaile){
                    setTimeout(() => {
                        this.retaile = fe;
                    }, 1);
                }
            
            let g=parseFloat(this.retailg);if(!g){g=0;}
            let d=parseFloat(this.retaild);if(!d){d=0;}
            let e=parseFloat(this.retaile);if(!e){e=0;}

            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Retail Deduction plus Exemption should not be more then Retail Gross"
                    }
            //fix the exemption overages
            this.checkexemptionoverages();
            
            //calculate taxes        
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Retail_01'];
            if(x<0){x="0.00";} 
            this.retailta=x;
            this.retailtd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatews(){

            let fg=this.fixfloat(this.wholesaleg);
                if(fg != this.wholesaleg){
                    setTimeout(() => {
                        this.wholesaleg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.wholesaled);
                if(fd != this.wholesaled){
                    setTimeout(() => {
                        this.wholesaled = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.wholesalee);
                if(fe != this.wholesalee){
                    setTimeout(() => {
                        this.wholesalee = fe;
                    }, 1);
                }

            let g=parseFloat(this.wholesaleg);if(!g){g=0;}
            let d=parseFloat(this.wholesaled);if(!d){d=0;}
            let e=parseFloat(this.wholesalee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Wholesale Deduction plus Exemption should not be more then Wholesale Gross"
                    }

            //fix the exemption overages
            this.checkexemptionoverages();

            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Wholesale_02'];
            if(x<0){x="0.00";} 
            this.wholesaleta=x;
            this.wholesaletd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculateservice(){
            
            let fg=this.fixfloat(this.serviceg);
                if(fg != this.serviceg){
                    setTimeout(() => {
                        this.serviceg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.serviced);
                if(fd != this.serviced){
                    setTimeout(() => {
                        this.serviced = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.servicee);
                if(fe != this.servicee){
                    setTimeout(() => {
                        this.servicee = fe;
                    }, 1);
                }

            let g=parseFloat(this.serviceg);if(!g){g=0;}
            let d=parseFloat(this.serviced);if(!d){d=0;}
            let e=parseFloat(this.servicee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Service Deduction plus Exemption should not be more then Service Gross"
                    }

            //fix the exemption overages
            this.checkexemptionoverages();

            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Service_03'];
            if(x<0){x="0.00";} 
            this.serviceta=x;
            this.servicetd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatemanufacturing(){
            
            let fg=this.fixfloat(this.manufacturingg);
                if(fg != this.manufacturingg){
                    setTimeout(() => {
                        this.manufacturingg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.manufacturingd);
                if(fd != this.manufacturingd){
                    setTimeout(() => {
                        this.manufacturingd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.manufacturinge);
                if(fe != this.manufacturinge){
                    setTimeout(() => {
                        this.manufacturinge = fe;
                    }, 1);
                }
            
            let g=parseFloat(this.manufacturingg);if(!g){g=0;}
            let d=parseFloat(this.manufacturingd);if(!d){d=0;}
            let e=parseFloat(this.manufacturinge);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Manufacturing Deduction plus Exemption should not be more then Service Gross"
                    }

            //fix the exemption overages
            this.checkexemptionoverages();

            //calculate tax
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['manufacturing'];
            if(x<0){x="0.00";} 
            this.manufacturingta=x;
            this.manufacturingtd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculateam(){
            let fg=this.fixfloat(this.amusementg);
                if(fg != this.amusementg){
                    setTimeout(() => {
                        this.amusementg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.amusementd);
                if(fd != this.amusementd){
                    setTimeout(() => {
                        this.amusementd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.amusemente);
                if(fe != this.amusemente){
                    setTimeout(() => {
                        this.amusemente = fe;
                    }, 1);
                }
            //let thisvue=this;
            let g=parseFloat(this.amusementg);if(!g){g=0;}
            let d=parseFloat(this.amusementd);if(!d){d=0;}
            let e=parseFloat(this.amusemente);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Amusement Device/Games Deduction plus Exemption should not be more then Amusement Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Amusement_09'];
            if(x<0){x="0.00";} 
            this.amusementta=x;
            this.amusementtd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatepp(){

            let fg=this.fixfloat(this.punchboardg);
                if(fg != this.punchboardg){
                    setTimeout(() => {
                        this.punchboardg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.punchboardd);
                if(fd != this.punchboardd){
                    setTimeout(() => {
                        this.punchboardd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.punchboarde);
                if(fe != this.punchboarde){
                    setTimeout(() => {
                        this.punchboarde = fe;
                    }, 1);
                }

            //let thisvue=this;
            let g=parseFloat(this.punchboardg);if(!g){g=0;}
            let d=parseFloat(this.punchboardd);if(!d){d=0;}
            let e=parseFloat(this.punchboarde);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['PunchcardProfit_10'];
            if(x<0){x="0.00";} 
            this.punchboardta=x;
            this.punchboardtd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatepnp(){

            let fg=this.fixfloat(this.punchboardnpg);
                if(fg != this.punchboardnpg){
                    setTimeout(() => {
                        this.punchboardnpg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.punchboardnpd);
                if(fd != this.punchboardnpd){
                    setTimeout(() => {
                        this.punchboardnpd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.punchboardnpe);
                if(fe != this.punchboardnpe){
                    setTimeout(() => {
                        this.punchboardnpe = fe;
                    }, 1);
                }

            //let thisvue=this;
            let g=parseFloat(this.punchboardnpg);if(!g){g=0;}
            let d=parseFloat(this.punchboardnpd);if(!d){d=0;}
            let e=parseFloat(this.punchboardnpe);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['PunchcardNonprofit_11'];
            if(x<0){x="0.00";} 
            this.punchboardnpta=x;
            this.punchboardnptd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatecp(){
            
            let fg=this.fixfloat(this.cardplayerg);
                if(fg != this.cardplayerg){
                    setTimeout(() => {
                        this.cardplayerg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.cardplayerd);
                if(fd != this.cardplayerd){
                    setTimeout(() => {
                        this.cardplayerd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.cardplayere);
                if(fe != this.cardplayere){
                    setTimeout(() => {
                        this.cardplayere = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.cardplayerg);if(!g){g=0;}
            let d=parseFloat(this.cardplayerd);if(!d){d=0;}
            let e=parseFloat(this.cardplayere);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);
            let y=this.taxrates['CardroomsPlayer_12'];  
            if(x<0){x="0.00";} 
            this.cardplayerta=x;
            this.cardplayertd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatech(){
            
            let fg=this.fixfloat(this.cardhouseg);
                if(fg != this.cardhouseg){
                    setTimeout(() => {
                        this.cardhouseg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.cardhoused);
                if(fd != this.cardhoused){
                    setTimeout(() => {
                        this.cardhoused = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.cardhousee);
                if(fe != this.cardhousee){
                    setTimeout(() => {
                        this.cardhousee = fe;
                    }, 1);
                }

            //let thisvue=this;
            let g=parseFloat(this.cardhouseg);if(!g){g=0;}
            let d=parseFloat(this.cardhoused);if(!d){d=0;}
            let e=parseFloat(this.cardhousee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2); 
            let y=this.taxrates['CardroomsHouse_13'];  
            if(x<0){x="0.00";} 
            this.cardhouseta=x;
            this.cardhousetd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatepbr(){
            
             let fg=this.fixfloat(this.bingog);
                if(fg != this.bingog){
                    setTimeout(() => {
                        this.bingog = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.bingod);
                if(fd != this.bingod){
                    setTimeout(() => {
                        this.bingod = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.bingoe);
                if(fe != this.bingoe){
                    setTimeout(() => {
                        this.bingoe = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.bingog);if(!g){g=0;}
            let d=parseFloat(this.bingod);if(!d){d=0;}
            let e=parseFloat(this.bingoe);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['BingoRaffle_14']; 
            if(x<0){x="0.00";} 
            this.bingota=x;
            this.bingotd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatepog(){
            
            let fg=this.fixfloat(this.gambleg);
                if(fg != this.gambleg){
                    setTimeout(() => {
                        this.gambleg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.gambled);
                if(fd != this.gambled){
                    setTimeout(() => {
                        this.gambled = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.gamblee);
                if(fe != this.gamblee){
                    setTimeout(() => {
                        this.gamblee = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.gambleg);if(!g){g=0;}
            let d=parseFloat(this.gambled);if(!d){d=0;}
            let e=parseFloat(this.gamblee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['OtherGambling_16']; 
            if(x<0){x="0.00";} 
            this.gambleta=x;
            this.gambletd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatepark(){
            
            let fg=this.fixfloat(this.parkg);
                if(fg != this.parkg){
                    setTimeout(() => {
                        this.parkg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.parkd);
                if(fd != this.parkd){
                    setTimeout(() => {
                        this.parkd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.parke);
                if(fe != this.parke){
                    setTimeout(() => {
                        this.parke = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.parkg);if(!g){g=0;}
            let d=parseFloat(this.parkd);if(!d){d=0;}
            let e=parseFloat(this.parke);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Parkinglot_21']; 
            if(x<0){x="0.00";} 
            this.parkta=x;
            this.parktd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculategarb(){
            
             let fg=this.fixfloat(this.garbageg);
                if(fg != this.garbageg){
                    setTimeout(() => {
                        this.garbageg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.garbaged);
                if(fd != this.garbaged){
                    setTimeout(() => {
                        this.garbaged = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.garbagee);
                if(fe != this.garbagee){
                    setTimeout(() => {
                        this.garbagee = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.garbageg);if(!g){g=0;}
            let d=parseFloat(this.garbaged);if(!d){d=0;}
            let e=parseFloat(this.garbagee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['GarbageUtility_26']; 
            if(x<0){x="0.00";} 
            this.garbageta=x;
            this.garbagetd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatecable(){
            
            let fg=this.fixfloat(this.cableg);
                if(fg != this.cableg){
                    setTimeout(() => {
                        this.cableg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.cabled);
                if(fd != this.cabled){
                    setTimeout(() => {
                        this.cabled = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.cablee);
                if(fe != this.cablee){
                    setTimeout(() => {
                        this.cablee = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.cableg);if(!g){g=0;}
            let d=parseFloat(this.cabled);if(!d){d=0;}
            let e=parseFloat(this.cablee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['CableUtility_29']; 
            if(x<0){x="0.00";} 
            this.cableta=x;
            this.cabletd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatetge(){
            
            let fg=this.fixfloat(this.tgeg);
                if(fg != this.tgeg){
                    setTimeout(() => {
                        this.tgeg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.tged);
                if(fd != this.tged){
                    setTimeout(() => {
                        this.tged = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.tgee);
                if(fe != this.tgee){
                    setTimeout(() => {
                        this.tgee = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.tgeg);if(!g){g=0;}
            let d=parseFloat(this.tged);if(!d){d=0;}
            let e=parseFloat(this.tgee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Punchboard/Pulltab (profit) Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Telephone_25']; 
            if(x<0){x="0.00";} 
            this.tgeta=x;
            this.tgetd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculatetel(){
            
             let fg=this.fixfloat(this.telephoneg);
                if(fg != this.telephoneg){
                    setTimeout(() => {
                        this.telephoneg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.telephoned);
                if(fd != this.telephoned){
                    setTimeout(() => {
                        this.telephoned = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.telephonee);
                if(fe != this.telephonee){
                    setTimeout(() => {
                        this.telephonee = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.telephoneg);if(!g){g=0;}
            let d=parseFloat(this.telephoned);if(!d){d=0;}
            let e=parseFloat(this.telephonee);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Telephone Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Telephone_25']; 
            if(x<0){x="0.00";} 
            this.telephoneta=x;
            this.telephonetd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculategas(){
            
            let fg=this.fixfloat(this.gasg);
                if(fg != this.gasg){
                    setTimeout(() => {
                        this.gasg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.gasd);
                if(fd != this.gasd){
                    setTimeout(() => {
                        this.gasd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.gase);
                if(fe != this.gase){
                    setTimeout(() => {
                        this.gase = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.gasg);if(!g){g=0;}
            let d=parseFloat(this.gasd);if(!d){d=0;}
            let e=parseFloat(this.gase);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Gas Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Gas_24']; 
            if(x<0){x="0.00";} 
            this.gasta=x;
            this.gastd=(x*y).toFixed(2);
            this.ttdue();
        },
        calculateele(){
            
            let fg=this.fixfloat(this.electricg);
                if(fg != this.electricg){
                    setTimeout(() => {
                        this.electricg = fg;
                    }, 1);
                }

            let fd=this.fixfloat(this.electricd);
                if(fd != this.electricd){
                    setTimeout(() => {
                        this.electricd = fd;
                    }, 1);
                }
            let fe=this.fixfloat(this.electrice);
                if(fe != this.electrice){
                    setTimeout(() => {
                        this.electrice = fe;
                    }, 1);
                }
            
            //let thisvue=this;
            let g=parseFloat(this.electricg);if(!g){g=0;}
            let d=parseFloat(this.electricd);if(!d){d=0;}
            let e=parseFloat(this.electrice);if(!e){e=0;}
            /////////////////////////////////////////////////////not sure if this message is needed
            if(g<(d+e)){
                    this.snackbar=true;
                    this.snackbarm="Electric Deduction plus Exemption should not be more then Punchboard/Pulltab Gross"
                    }
            let x=(g-(d+e)).toFixed(2);  
            let y=this.taxrates['Electric_23']; 
            if(x<0){x="0.00";} 
            this.electricta=x;
            this.electrictd=(x*y).toFixed(2);
            this.ttdue();
        },
      getaccount(){
        this.acct = this.$store.state.acct;
        this.getaccount2();

      },
      
      getaccount2(){
        let thisvue=this;
        let prikey=this.acct.fprikey;
        let url = this.$store.state.url;
        const params = new URLSearchParams();
        params.append('ty', 'acntDetails');
        params.append('prikey' , prikey);
        
        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
            thisvue.acctdetails = response.data;
            thisvue.gettaxperiod();
            
        })
        .then(function(){
            thisvue.getbalance();
        });
        
      },
      getbalance(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let acnt=this.acctdetails.AcctNo;
          params.append('ty', 'getaccountbalance');
          params.append('acnt', acnt);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            thisvue.balanceforward=response.data[0].toFixed(2);
          })
          .then(function(){
              thisvue.amountp();
          });
        },
      gettaxperiod(){
        let d=new Date();
        let y=d.getFullYear();
        let x=this.acctdetails.Freq; 

        if(x== 'A') {
            let aaa="Annual "+(parseInt(y)-3);
            let aa="Annual "+(parseInt(y)-2);
            let a="Annual "+(parseInt(y)-1);
            let b="Annual "+parseInt(y);
            let c="Annual "+(parseInt(y)+1);
            this.taxperiods=[aaa,aa,a,b,c];
            let m=d.getMonth();
            if(m<9){this.taxperiod=a;this.datedue="April 30, "+y }else{this.taxperiod=b;this.datedue="April 30, "+(parseInt(y)+1)}
        }
        if(x=='Q'){
            let m=d.getMonth()+1;
            y=parseInt(y);
            if(m==3 || m==4 || m==5){
                let a="Q1 "+ (y-1);
                let b="Q2 "+ (y-1);
                let c="Q3 "+ (y-1);
                let d="Q4 "+ (y-1);
                let e="Q1 "+ (y);
                let f="Q2 "+ (y);
                this.taxperiods=[a,b,c,d,e,f];
                this.taxperiod=e;
                this.datedue="April 30, "+y;
            }
            if(m==6 || m==7 || m==8){
                let a="Q1 "+ (y-1);
                let b="Q2 "+ (y-1);
                let c="Q3 "+ (y-1);
                let d="Q4 "+ (y-1);
                let e="Q1 "+ (y);
                let f="Q2 "+ (y);
                let g="Q3 "+ (y);
                this.taxperiods=[a,b,c,d,e,f,g];
                this.taxperiod=f;
                this.datedue="July 31, "+y;
            }
            if(m==9 || m==10 || m==11){
                let a="Q1 "+ (y-1);
                let b="Q2 "+ (y-1);
                let c="Q3 "+ (y-1);
                let d="Q4 "+ (y-1);
                let e="Q1 "+ (y);
                let f="Q2 "+ (y);
                let g="Q3 "+ (y);
                let h="Q4 "+ (y);
                this.taxperiods=[a,b,c,d,e,f,g,h];
                this.taxperiod=g;
                this.datedue="October 31, "+y;
            }
            if(m==12){
                let a="Q1 "+ (y-1);
                let b="Q2 "+ (y-1);
                let c="Q3 "+ (y-1);
                let d="Q4 "+ (y-1);
                let e="Q1 "+ (y);
                let f="Q2 "+ (y);
                let g="Q3 "+ (y);
                let h="Q4 "+ (y);
                this.taxperiods=[a,b,c,d,e,f,g,h];
                this.taxperiod=h;
                this.datedue="January 31, "+(y+1);
            }
            if(m==1 || m==2){
                let a="Q1 "+ (y-1);
                let b="Q2 "+ (y-1);
                let c="Q3 "+ (y-1);
                let d="Q4 "+ (y-1);
                let e="Q1 "+ (y);
                this.taxperiods=[a,b,c,d,e];
                this.taxperiod=d;
                this.datedue="January 31, "+y;
            }
            
        }
        if(x=='M'){
            let m=d.getMonth()+1;
            m=parseInt(m);
            y=parseInt(y);
            let yy=y-1;
            //let yyy=y+1;
            let a1="January "+yy;let a2="February "+yy;let a3="March "+yy;let a4="April "+yy;let a5="May "+yy;let a6="June "+yy;
            let a7="July "+yy;let a8="August "+yy;let a9="September "+yy;let a10="October "+yy;let a11="November "+yy;let a12="December "+yy;
            let b1="January "+y;let b2="February "+y;let b3="March "+y;let b4="April "+y;let b5="May "+y;let b6="June "+y;
            let b7="July "+y;let b8="August "+y;let b9="September "+y;let b10="October "+y;let b11="November "+y;//let b12="December "+y;
            //let c1="January "+yyy;
            if(m==1){
                    this.taxperiod=a12;this.datedue="January 31,"+y;
                    this.taxperiods=[a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12];
                    }
            if(m==2){
                    this.taxperiod=b1;this.datedue="February 28,"+y;
                    this.taxperiods=[a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,b1];
                    }
            if(m==3){
                    this.taxperiod=b2;this.datedue="March 31,"+y;
                    this.taxperiods=[a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,b1,b2];
                    }
            if(m==4){
                    this.taxperiod=b3;this.datedue="April 30,"+y;
                    this.taxperiods=[a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,b1,b2,b3];
                    }
            if(m==5){
                    this.taxperiod=b4;this.datedue="May 31,"+y;
                    this.taxperiods=[a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,b1,b2,b3,b4];
                    }
            if(m==6){
                    this.taxperiod=b5;this.datedue="June 30,"+y;
                    this.taxperiods=[a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11,a12,b1,b2,b3,b4,b5];
                    }
            if(m==7){
                    this.taxperiod=b6;this.datedue="July 31,"+y;
                    this.taxperiods=[a7,a8,a9,a10,a11,a12,b1,b2,b3,b4,b5,b6];
                    }
            if(m==8){
                    this.taxperiod=b7;this.datedue="August 31,"+y;
                    this.taxperiods=[a7,a8,a9,a10,a11,a12,b1,b2,b3,b4,b5,b6,b7];
                    }
            if(m==9){
                    this.taxperiod=b8;this.datedue="September 30,"+y;
                    this.taxperiods=[a7,a8,a9,a10,a11,a12,b1,b2,b3,b4,b5,b6,b7,b8];
                    }
            if(m==10){
                    this.taxperiod=b9;this.datedue="October 31,"+y;
                    this.taxperiods=[b1,b2,b3,b4,b5,b6,b7,b8,b9];
                    }
            if(m==11){
                    this.taxperiod=b10;this.datedue="November 30,"+y;
                    this.taxperiods=[b1,b2,b3,b4,b5,b6,b7,b8,b9,b10];
                    }
            if(m==12){
                    this.taxperiod=b11;this.datedue="December 31,"+y;
                    this.taxperiods=[b1,b2,b3,b4,b5,b6,b7,b8,b9,b10,b11];
                    }
            }
            
        
        if(!x || x==''){
            //these people do not pay taxes apperently, so open message to let them know
            this.notax=true;
        }  
        let yr=this.taxperiod;
        let yrA=yr.split(" ");
        this.taxyear=yrA[1];
        this.gettaxrate2();
      },
      taxpinput(){
        
        //let d=new Date();
        //let y=d.getFullYear();
        let x=this.acctdetails.Freq; 
        if(x=="A"){

            

            //get the year and change due date to the following april 30th
            let t=this.taxperiod;
            let tA=t.split(" ");
            let y=parseInt(tA[1])+1;
            this.datedue="April 30, "+y.toString();

        }
        if(x=="Q"){
            let t=this.taxperiod;
            let tA=t.split(" ");
           
            if(tA[0] == "Q1"){this.datedue = "April 30, "+tA[1].toString();}
            if(tA[0] == "Q2"){this.datedue = "July 31, "+tA[1].toString();}
            if(tA[0] == "Q3"){this.datedue = "October 31, "+tA[1].toString();}
            if(tA[0] == "Q4"){
                let a=parseInt(tA[1]);
                a++;
                this.datedue = "January 31, "+a.toString();}
            
        }
        if(x=="M"){
            let t=this.taxperiod;
            let tA=t.split(" ");
            let m=tA[0];
            let y=tA[1];
            let y2=parseInt(y)+1;
            let d="";
            if(m=="January"){d="February 28, "+y;}
            if(m=="February"){d="March 31, "+y;}
            if(m=="March"){d="April 30, "+y;}
            if(m=="April"){d="May 31, "+y;}
            if(m=="May"){d="June 30, "+y;}
            if(m=="June"){d="July 31, "+y;}
            if(m=="July"){d="August 31, "+y;}
            if(m=="August"){d="September 30, "+y;}
            if(m=="September"){d="October 31, "+y;}
            if(m=="October"){d="November 30, "+y;}
            if(m=="November"){d="December 31, "+y;}
            if(m=="December"){d="January 31, "+y2.toString();}
            this.datedue=d;
        }
        this.penaltydue();
        this.gettaxrate2();
        let yr=this.taxperiod;
        let yrA=yr.split(" ");
        this.taxyear=yrA[1];
      },
      gohome(){
        this.$router.replace({ name: "Home" });
      },
      gohome2(){
        window.open(this.ifs, "_blank");
        this.$router.replace({ name: "Home" });
      },
      fixfloat(x){
          //remove all letters
          let pattern = /[^0-9.]*$/g;
          x=x.replace(pattern, '');
          //remove more than two places past the decimal point
        if(x.includes(".")){
            let xA=x.split(".");
            if(xA[1]){
                xA[1]=xA[1].substring(0,2);
                x=xA[0]+"."+xA[1];
                }else{
                    x=xA[0]+".";
                }
            }
          return x;
        },
       
    },
    mounted(){
      window.scrollTo(0,0);
      let a= this.$store.state.authent;
      if(!a) {
        this.$router.replace({ name: "login" });
      }else{
        this.getaccount();
      }
    }
}
</script>

<style>
.label-left .v-label {
  left: 0 !important;
  transform-origin: top left !important;
}
</style>