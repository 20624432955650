            dialogupdate
<template>
  <v-app>

    <v-snackbar
      v-model="snackbar"
      centered
      :color="snackbarcolor"
    >
      {{ snackbartext }}
        <v-btn
          color="amber lighten-4"
          text
          @click="snackbar = false"
        >
          Close
        </v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogupdate" width="600">
      <v-card width="600">
        <v-card-title>UPDATE {{updatebusname}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col class="pb-0 pt-8">
              <v-text-field v-if="updtdFN" dense outlined @input="updtd(1)" label="First Name" placeholder=" " v-model="updatefirstname"></v-text-field>
            </v-col>
            <v-col class="pb-0 pt-8">
              <v-text-field v-if="updtdLN" dense outlined @input="updtd(2)" label="Last Name" placeholder=" " v-model="updatelastname"></v-text-field>
            </v-col>
          </v-row>
          <v-row class=" py-0">
            <v-col class="py-0">
              <v-text-field v-if="updtdPH" dense outlined @input="updtd(3)" label="Phone" placeholder=" " v-model="updatephone"></v-text-field>
            </v-col>
            <v-col class=" py-0">
              <v-text-field v-if="updtdPO" dense outlined @input="updtd(4)" label="Position" placeholder=" " v-model="updateposition"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class=" py-0">
              <v-text-field v-if="updtdEM" dense outlined @input="updtd(5)" label="Email" placeholder=" " v-model="updateemail"></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-radio-group v-if="multipleaccounts" v-model="passwordRG" class="ma-0 pa-0">
                <v-radio
                 label="Just for this Business"
                 value="a"
                ></v-radio>
                <v-radio
                 label="Change for All Businesses"
                 value="b"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="green"
            @click="dialogupdate=false"
          >Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-4"
            @click="UpdateAcountsAll()"
          >Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" width="800">
      <v-card width="800">
        <v-card-title>Change Password - {{cpbusname}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row class="mt-7">
            <v-col justify="center">
              <v-text-field
                v-model="password"
                :rules="[rules.required, rules.min]" 
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                :type="show1 ? 'text' : 'password'" 
                @click:append="show1 = !show1"
                outlined
                placeholder=" "
                label="Password"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
              v-model="verify"
              :rules="[rules.required, passwordMatch]" 
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
              :type="show1 ? 'text' : 'password'" 
              @click:append="show1 = !show1"
              outlined
              placeholder=" "
              label="Verify Password"
            ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-radio-group v-if="multipleaccounts" v-model="passwordRG" class="ma-0 pa-0">
                <v-radio
                 label="Just for this Business"
                 value="a"
                ></v-radio>
                <v-radio
                 label="Change for All Businesses"
                 value="b"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            color="green"
            @click="dialog=false"
          >Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="green darken-4"
            @click="UpdateP()"
          >Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row justify="center" >
      <v-card 
        class="px-4 mt-8 mx-4"
        v-for="l in loginaccnt" 
        :key="l.prikey"
        color="green lighten-5"
        max-width="750px"
        min-width="750px"
        max-height="300px"
        min-height="300px"
        >
        <v-card-title class="ma-0 pa-0">{{l.BusName}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="ma-0 pa-0">
          <v-row class="ma-0 pa-0" >
            <v-col class="ma-0 pa-0">
              Business Description: {{l.BusDesc}}<br/>
              Business Phone: {{l.phone}}<br/>
              Location Address1: {{l.LocAdr1}}<br/>
              Location Address2: {{l.LocAdr2}}<br/>
              Location Address3: {{l.LocAdr3}}<br/>
              Location City: {{l.LocCity}} {{l.LocState}}, {{l.LocZip}}
            </v-col>
            <v-col class="ma-0 pa-0">
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 py-0 px=2">
                  <v-text-field @input="updtd(1)" :id="'fn'+l.prikey" dense outlined label="Firstname" :value="l.firstname"></v-text-field>
                  <v-text-field @input="updtd(3)" :id="'ph'+l.prikey" dense outlined label="Phone" :value="l.phone"></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0">
                  <v-text-field @input="updtd(2)" :id="'ln'+l.prikey" dense outlined label="Lastname" :value="l.lastname"></v-text-field>
                  <v-text-field @input="updtd(4)" :id="'po'+l.prikey" dense outlined label="Position" :value="l.position"></v-text-field>
                </v-col>

              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-text-field :id="'em'+l.prikey" 
                                class="ma-0 pl-3" 
                                 @input="updtd(5)"
                                dense 
                                outlined 
                                label="Email" 
                                :value="l.email"
                                persistent-hint
                                hint="This will change the login for this account"
                                >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn elevation="10" color="green lighten-2" @click="cp(l.prikey,l.BusName)">Change Password</v-btn>
          <v-spacer></v-spacer>
          <v-btn elevation="10" color="green lighten-2" @click="preupdate(l.prikey,l.BusName)">Update Account</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-row> 
  </v-app>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
        loginaccnt:[],
        passwordRG:'a',
        multipleaccounts:false,
        show1: false,
        dialog:false,
        dialogupdate:false,
        snackbar:false,
        snackbartext:'',
        snackbarcolor:'',
        verify:'',
        password:'',
        cpprikey:'',
        updateprikey:'',
        cpbusname:'',
        updatebusname:'',
        updatefirstname:'',
        updatelastname:'',
        updatephone:'',
        updateposition:'',
        updateemail:'',
        updtdFN:false,
        updtdLN:false,
        updtdPH:false,
        updtdPO:false,
        updtdEM:false,

        rules: {
            required: value => !!value || "Required.",
            min: v => (v && v.length >= 8) || "Min 8 characters",
            valid: v => /.+@.+\..+/.test(v) || "E-mail must be valid",
          }
    }),
    computed: {
      passwordMatch() {
        return () => this.password === this.verify || "Password must match";
      }
    },
    methods:{
      updtd(x){
        if(x==1){this.updtdFN=true;}
        if(x==2){this.updtdLN=true;}
        if(x==3){this.updtdPH=true;}
        if(x==4){this.updtdPO=true;}
        if(x==5){this.updtdEM=true;}

      },
      cp(p,b){
        this.dialog=true;
        this.cpprikey=p;
        this.cpbusname=b;
      },
      UpdateP(){
        let thisvue=this;
        let url = this.$store.state.url;
        
        if(this.passwordRG == 'a'){
          const params = new URLSearchParams();
          params.append('ty', 'updatepassword');
          params.append('prikey' , this.cpprikey);
          params.append('pw', this.password);

          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            if(response.data == 'good'){
              thisvue.snackbar=true;
              thisvue.snackbartext="Your Password has been updated";
              thisvue.snackbarcolor="green darken-4"
            }else{
              thisvue.snackbar=true;
              thisvue.snackbartext="ERROR!! Your Password has NOT been updated";
              thisvue.snackbarcolor="red darken-4"
            }
            thisvue.dialog=false;
          });
        }else{
          //get all the prikeys
          let p='';
          for(let i=0;i<this.loginaccnt.length;i++){
            p=p+'|'+this.loginaccnt[i].prikey;
          }
          const params = new URLSearchParams();
          params.append('ty', 'updatepasswords');
          params.append('prikey' , p);
          params.append('pw', this.password);

          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            if(response.data == 'good'){
              thisvue.snackbar=true;
              thisvue.snackbartext="Your Password has been updated";
              thisvue.snackbarcolor="green darken-4"
            }else{
              thisvue.snackbar=true;
              thisvue.snackbartext="ERROR!! Your Password has NOT been updated";
              thisvue.snackbarcolor="red darken-4"
            }
            thisvue.dialog=false;
          });
        }
      },
      preupdate(p,b){
        if(this.loginaccnt.length == 1){
            this.update(p);
          }else{
            this.dialogupdate=true;
            this.updateprikey=p;
            this.updatebusname=b;
            this.updatefirstname = document.getElementById("fn"+p).value;
            this.updatelastname = document.getElementById("ln"+p).value;
            this.updatephone = document.getElementById("ph"+p).value;
            this.updateposition = document.getElementById("po"+p).value;
            this.updateemail = document.getElementById("em"+p).value;
        }
      },
      update(p){
        let thisvue=this;

      let url=this.$store.state.url;
      let fn = document.getElementById("fn"+p).value;
      let ln = document.getElementById("ln"+p).value;
      let ph = document.getElementById("ph"+p).value;
      let po = document.getElementById("po"+p).value;
      let em = document.getElementById("em"+p).value;
      let prikey = p;

       const params = new URLSearchParams();
        params.append('ty', 'updatelogindata');
        params.append('fn' , fn);
        params.append('ln' , ln);
        params.append('ph' , ph);
        params.append('po' , po);
        params.append('em' , em);
        params.append('prikey' , prikey);
        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
           if(response.data == 'good'){
              thisvue.snackbar=true;
              thisvue.snackbartext="Your Account has been updated";
              thisvue.snackbarcolor="green darken-4"
            }else{
              thisvue.snackbar=true;
              thisvue.snackbartext="ERROR!! Your Account has NOT been updated";
              thisvue.snackbarcolor="red darken-4"
            }
            thisvue.getdata();
        });
      },
      UpdateAcountsAll(){
        let p=this.updateprikey;

        if(this.passwordRG=='a'){
          document.getElementById("fn"+p).value=this.updatefirstname;
          document.getElementById("ln"+p).value=this.updatelastname;
          document.getElementById("ph"+p).value=this.updatephone;
          document.getElementById("po"+p).value=this.updateposition;
          document.getElementById("em"+p).value=this.updateemail;
          this.cpprikey=p;
          this.dialogupdate=false;
          
          this.updtdFN=false;
          this.updtdLN=false;
          this.updtdPH=false;
          this.updtdPO=false;
          this.updtdEM=false;
          this.update(p);
        }else{
          /////////////////////////////////////udpate all accounts
          //get all the prikeys
          let p='';
          let thisvue = this;
          let url = this.$store.state.url;
          for(let i=0;i<this.loginaccnt.length;i++){
            p=p+'|'+this.loginaccnt[i].prikey;
          }
          const params = new URLSearchParams();
          params.append('ty', 'updateaccounts');
          params.append('prikey' , p);
          if(this.updtdFN == true){params.append('fn', this.updatefirstname);}
          if(this.updtdLN == true){params.append('ln', this.updatelastname);}
          if(this.updtdPH == true){params.append('ph', this.updatephone);}
          if(this.updtdPO == true){params.append('po', this.updateposition);}
          if(this.updtdEM == true){params.append('em', this.updateemail);}

          this.updtdFN=false;
          this.updtdLN=false;
          this.updtdPH=false;
          this.updtdPO=false;
          this.updtdEM=false;

          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
              if(response.data == 'good'){
              thisvue.snackbar=true;
              thisvue.snackbartext="Your Accounts have been updated";
              thisvue.snackbarcolor="green darken-4"
            }else{
              thisvue.snackbar=true;
              thisvue.snackbartext="ERROR!! Your Accounts have NOT been updated";
              thisvue.snackbarcolor="red darken-4"
            }
            thisvue.dialogupdate=false;
            thisvue.getdata();
          });

        }
      },
      getdata(){
        let thisvue=this;
        let url = this.$store.state.url;
        let p = this.$store.state.p;
        let em = this.$store.state.acct.email;

        const params = new URLSearchParams();
        params.append('ty', 'login2');
        params.append('em' , em);
        params.append('pw', p);

        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
          if(response.data.length>1){
            thisvue.multipleaccounts=true;
            }
          for(let i=0;i<response.data.length;i++){
            response.data[i].firstname=response.data[i].firstname.trim();
            response.data[i].lastname=response.data[i].lastname.trim();
            response.data[i].phone=response.data[i].phone.trim();
            response.data[i].position=response.data[i].position.trim();
            response.data[i].email=response.data[i].email.trim();
          }
          thisvue.loginaccnt=response.data;
        });
      }
    },
    mounted(){
        window.scrollTo(0,0);
      let a= this.$store.state.authent;
      if(!a) {
        this.$router.replace({ name: "login" });
      }else{
        this.getdata();
      }
      
    }
}
</script>

<style>

</style>