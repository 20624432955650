<template>
  <div>
    <v-app>
      <v-snackbar
        v-model="snackbar"
        color="error"
        timeout=10000
        shaped
        transition="fab-transition"
        centered
        >
        <h3>{{snackbarmsg}}</h3>

        <template v-slot:action="{ attrs }">
            <v-btn
            dark
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
    </v-snackbar>
    <v-dialog v-model="dialog2" persistent max-width="600px" min-width="360px">
        <v-card>
            <v-card-title>Businesses</v-card-title>
            <v-card-text>
                <div v-for="a in athfor" :key="a.AcctNo">
                    <v-btn 
                        v-if="a.approved==='suspended'"
                        @click="gohome(a)"
                        block 
                        color="red"
                        disabled
                        elevation="12"
                        class="my-4"> Suspended  (<small>{{a.AcctNo}} {{a.BusName}}</small>) 
                    </v-btn>
                    <v-btn 
                        v-if="!a.approved"
                        @click="gohome(a)"
                        block 
                        color="black lighten-3"
                        dark
                        disabled
                        elevation="12"
                        class="my-4"> awaiting approval (<small>{{a.AcctNo}} {{a.BusName}}</small>)
                    </v-btn>
                    
                    <v-btn 
                        v-if="a.approved==='yes' && a.AcctDb == 'L'"
                        @click="gohome(a)"
                        block 
                        color="green"
                        elevation="12"
                        class="my-4">{{a.AcctNo}} {{a.BusName}}
                    </v-btn>
                    <v-btn
                        v-if="a.approved==='yes' && a.AcctDb == 'R'"
                        @click="golandlord(a)"
                        block 
                        color="blue lighten-2"
                        elevation="12"
                        class="my-4">Landlord - {{a.AcctNo}} - {{a.BusName}}
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
        <v-dialog v-model="dialog" persistent max-width="640px" min-width="360px">
            <div>
                <v-tabs v-model="tab" show-arrows background-color="teal darken-1" icons-and-text dark grow>
                    <v-tabs-slider color="purple darken-4"></v-tabs-slider>
                    <v-tab >
                        <v-icon large>mdi-account</v-icon>
                        <div class="caption py-1">Login</div>
                    </v-tab>
                    <v-tab >
                        <v-icon large>mdi-account-outline</v-icon>
                        <div class="caption py-1">Register</div>
                    </v-tab>
                    <v-tab-item>
                        <v-card class="px-4">
                            <v-card-text>
                                <v-row justify="center">
                                    <em>
                                        You must register before you login
                                    </em>
                                </v-row>
                                <v-form ref="loginForm" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field ref="loginem" v-model="loginEmail" :rules="loginEmailRules" label="E-mail" autofocus required></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="loginPassword" 
                                                ref = "linpass"
                                                :append-icon="show1?'eye':'eye-off'" 
                                                :rules="[rules.required, rules.min]" 
                                                :type="show1 ? 'text' : 'password'" 
                                                name="input-10-1" 
                                                label="Password" 
                                                hint="At least 8 characters" 
                                                counter 
                                                @click:append="show1 = !show1"
                                                ></v-text-field>
                                        </v-col>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                            <v-btn ref="lbtn" 
                                                x-large 
                                                block 
                                                :disabled="!valid" 
                                                color="success" 
                                                @click="validate"
                                                > Login </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                   
                    <v-tab-item>
                        <v-card class="px-4">
                            <v-card-text>
                                <v-form ref="registerForm" v-model="valid" lazy-validation>
                                     <transition name="fade" mode="out-in" >
                                        <div>
                                        <v-row  v-if="accntshow" class="ma-1 pa-1">
                                            
                                                <v-col >
                                                    <v-text-field  
                                                        label="Account/License Number" 
                                                        width="0"  
                                                        v-model="accntno"
                                                        type="number"
                                                        ></v-text-field>
                                                </v-col> 
                                                <v-col cols="2" class="mt-5"><center><b>and</b></center></v-col>
                                                <v-col >
                                                    <v-text-field  
                                                        label="UBI Number" 
                                                        width="0"  
                                                        v-model="ubino"
                                                        type="number"
                                                        ></v-text-field>
                                                </v-col>
                                                
                                                    
                                               
                                        </v-row> 
                                        <v-row  v-if="accntshow" class="ma-1 pa-1">
                                            <v-col  class="ma-2 pa-2">
                                                        <div>Please enter both the UBI number and the Account number. We need both to register your business</div>
                                            </v-col>
                                            <v-col>
                                                <v-card-actions>
                                                        <v-spacer></v-spacer><v-btn block class="" color="teal darken-3" dark  height="56" @click="checkaccnt">Check Account</v-btn>
                                                    </v-card-actions>
                                            </v-col>
                                        </v-row>
                                    </div>
                                     </transition>
                                         <transition name="fade" mode="out-in" >
                                         <v-row v-if="accntshow" > 
                                            
                                                <v-col rows="12">
                                                <div v-for="a in accntresults"
                                                    :key="a.prikey"
                                                    >
                                                    <v-card class="ma-8 pa-1">
                                                        <v-card-title>
                                                            {{a.BusName}}
                                                        </v-card-title>
                                                        <v-divider></v-divider>
                                                        <v-card-text>
                                                            {{a.LocAdr1}}<br/>
                                                            {{a.LocAdr2}}<br/>
                                                            {{a.LocAdr3}}<br/>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            
                                                            <v-spacer></v-spacer>
                                                            <v-btn @click="useaccnt(a)" color="success">Register for this Account</v-btn>
                                                        </v-card-actions>

                                                    </v-card>
                                                        
                                                </div>
                                                </v-col>
                                            
                                        </v-row>
                                     </transition>
                                     <transition name="fade" mode="out-in" >
                                        <v-row v-if="registershow">
                                            <v-col cols="12">
                                                <v-row justify="center">
                                                   <h2>{{businessname}}</h2> 
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="firstName" :rules="[rules.required]" label="First Name" maxlength="20" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="lastName" :rules="[rules.required]" label="Last Name" maxlength="20" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="phone" :rules="[rules.required]" label="Phone" maxlength="20" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="pos" :rules="textRules" label="Position" maxlength="20" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field v-model="password" 
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                                                    :rules="[rules.required, rules.min]" 
                                                    :type="show1 ? 'text' : 'password'" 
                                                    name="input-10-2" 
                                                    label="Password" 
                                                    hint="At least 8 characters" 
                                                    counter 
                                                    @click:append="show1 = !show1"
                                                    ></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-text-field block 
                                                    v-model="verify" 
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
                                                    :rules="[rules.required, passwordMatch]" 
                                                    :type="show1 ? 'text' 
                                                    : 'password'" 
                                                    name="input-10-3" 
                                                    label="Confirm Password" 
                                                    counter 
                                                    @click:append="show1 = !show1"
                                                    ></v-text-field>
                                            </v-col>
                                            <v-spacer></v-spacer>
                                            <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
                                                <v-btn x-large block :disabled="!valid" color="success" @click="register">Register</v-btn>
                                            </v-col>
                                        </v-row>
                                     </transition>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </div>
        </v-dialog>
        
            <v-dialog
            v-model="response"
            persistent
            max-width="500px" min-width="360px"
            class="ma-0 pa-0"
            >
            <v-card  class="ma-0 pa-0">
                <v-card-title
                class="headline grey lighten-2 pa-4 ma-0"
                primary-title
                >
                {{ttl}}
                </v-card-title>

                <v-card-text class="pa-4">
                {{msg}}
                <v-fab-transition>
                    <v-row justify="center">
                <v-img
                    v-if="changepasspic"
                    max-height="150"
                    max-width="200"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    src="../assets/send-mail.png"
                ></v-img>
                </v-row>
                </v-fab-transition>
                </v-card-text>

                <v-divider></v-divider>
                
                <div  class="pa-0">
                    <v-card-actions>
                    <v-btn
                 v-if="dups"
                        color="primary"
                        text
                     @click="dupstryagain"
               >
                  Try Again
                    </v-btn>
                  <v-spacer v-if="newpass" ></v-spacer>
                    <v-btn
                        v-if="newpass"
                        color="primary"
                        text
                        @click="getnewpass"
                    >
                        Change Password
                    </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        
    </v-app>
</div>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
    athfor:[],
    notathfor:[],
    newpass:false,
    businessname:'',
    snackbar:false,
    snackbarmsg:'',
    position:'',
    dialog: true,
    dialog2:false,
    response:false,
    registershow:false,
    accntshow:true,
    accntno:'',
    ubino:'',
    accntresults:[],
    ttl:"Register",
    msg:"Thanx!",
    dups:false,
    tab: 0,
    valid: true,
    firstName: "",
    lastName: "",
    phone:"",
    fkey:"",
    pos:"",
    company:"",
    email: "",
    changepasspic:false,
    password: "",
    verify: "",
    loginPassword: "",
    loginEmail: "",
    show1: false,
    loginEmailRules: [
      v => !!v || "Required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    emailRules: [
      v => !!v || "Required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    textRules: [
      v => !!v || "Required",
      v => /^[a-z @!0-9]+$/gi.test(v) || "no special characters"
    ],
    rules: {
      required: value => !!value || "Required.",
      min: v => (v && v.length >= 8) || "Min 8 characters",
      
    }
  }),
    computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    }
  },
  methods: {
        golandlord(a){
            window.console.log(a);
            this.$store.commit('setAuth',false);
            window.location.href = this.$store.state.url3;
        },
        getnewpass(){
        //thisvue=this;
        //this.dialog = false;
        //this.response = true;
        //this.newpass2 = true;
        this.newpass = false;
        this.changepasspic=true;       
        let url = this.$store.state.url;
        let thisvue=this;
        const params = new URLSearchParams();
        params.append('ty', 'emailpass');
        params.append('em', this.loginEmail);
        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
            let r=response.data.trim();
            //window.console.log(r);
            if(r == "inserted"){
                thisvue.msg = "An email has been sent to you with a link to change your password. Please check your email, change your password, and then try again. If you can't find an email in your inbox then please check your junk mail and spam filters";
                thisvue.ttl = "Change Password";
            }else{
                thisvue.msg = "Please call the City of Bremerton for help.";
                thisvue.ttl = "Error Changing Password";
            }
        });

    },
      wrongaccnt(){
          this.accntresults=[];
          this.accntno="";
      },
      useaccnt(a){
        //window.console.log(a);
        this.accntshow=false;
        this.registershow=true;
        this.businessname = a.BusName;
        this.fkey=a.prikey;

      },
      checkaccnt(){
          if(this.ubino.length<9){
            this.snackbarmsg="UBI must be at least 9 digits long";
            this.snackbar=true;
            return null;
          }
          if(this.ubino.length<9 || this.accntno.length<4){
            this.snackbarmsg="We need both the UBI and Account Number";
            this.snackbar=true;
            return null;
          }
            let thisvue=this;
            let url=this.$store.state.url;
            const params = new URLSearchParams();
            params.append('ty', 'checkacntno');
            params.append('acntno' , this.accntno);
            params.append('TaxId' , this.ubino);
            axios({
                method:"post",
                url:url,
                data:params
            })
            .then(function(response){
                if(!response.data){
                    thisvue.snackbarmsg="Could not find Account Number "+thisvue.accntno+" With that UBI!";
                    thisvue.snackbar=true;
                }
                
                thisvue.accntresults=response.data;
                if(thisvue.accntresults[0].LocAdr2=="null"){thisvue.accntresults[0].LocAdr2="";}
                if(thisvue.accntresults[0].LocAdr3=="null"){thisvue.accntresults[0].LocAdr3="";}
            });
      },
    validate() {
      if (this.$refs.loginForm.validate()) {
        
        let thisvue = this;
        let url = this.$store.state.url;

        let e = this.loginEmail;
        let p = this.loginPassword;
        thisvue.$store.commit('setp',p);

        const params = new URLSearchParams();
        params.append('ty', 'login2');
        params.append('em' , e);
        params.append('pw', p);

        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
            let r = response.data.toString().trim();

            if(r === "not authorized"){
                    thisvue.dialog = false;
                    thisvue.response = true;
                    thisvue.dups = true;
                    thisvue.msg = "This account has not been approved yet, please try again later or call the City of Bremerton for further information, thanx!";
                    thisvue.ttl = "Not Approved";
            }
            else if(r === "bad password"){
                    thisvue.dialog = false;
                    thisvue.response = true;
                    thisvue.newpass = true;
                    thisvue.dups = true;
                    thisvue.msg = "The password that you supplied does not match what we have on record. Please try again, change your password, or call the City of Bremerton for help.";
                    thisvue.ttl = "Password Mismatch";
            }
            else if(r === "bad email"){
                thisvue.dialog = false;
                    thisvue.response = true;
                    thisvue.dups = true;
                    thisvue.msg = "This email is not in our database, please try again or call the City of Bremerton for help.";
                    thisvue.ttl = "Email Mismatch";
            }
            else{
                
                let r = response.data;
                 
                    for(let i=0;i<r.length;i++){
                        //window.console.log(r[i]);
                        if(r[i].approved){r[i].approved = r[i].approved.trim();}
                        if(r[i].BusName ){r[i].BusName = r[i].BusName.trim();}
                        if(r[i].firstname){r[i].firstname = r[i].firstname.trim();}
                        if(r[i].lastname){r[i].lastname = r[i].lastname.trim();}
                        if(r[i].phone){r[i].phone = r[i].phone.trim();}
                        if(r[i].position){r[i].position = r[i].position.trim();}

                    }
                thisvue.athfor = r;
                thisvue.$store.commit('setAuth',true);
                thisvue.$parent.eml = e;
                thisvue.dialog=false;
                thisvue.dialog2=true;
                //thisvue.$parent.$router.replace({ name: "home" });
            }

        });
      }
    },
    dupstryagain(){
        this.dialog = true;
        this.response = false;
        this.$parent.authent = false;
        this.changepasspic = false;
    },
    register(){
        if (this.$refs.registerForm.validate()) {
        let thisvue = this;
        let url = this.$store.state.url;
        let fk = this.fkey;
        let l = this.lastName;
        let f = this.firstName;
        let p = this.phone;
        let e = this.email;
        let pw = this.password;
        let po = this.pos;

        const params = new URLSearchParams();
        params.append('fk', fk);
        params.append('ln', l);
        params.append('fn', f);
        params.append('ph', p);
        params.append('po', po);
        params.append('em', e);
        params.append('pw', pw);
        params.append('ty', 'register');

        axios({
            method:"post",
            url:url,
            data:params
        })
          .then(function (response) {
               let d = response.data.toString().trim();
               if(d=="duplicate"){
                   thisvue.snackbarmsg=thisvue.email+" is already being used with "+thisvue.businessname+". Please Login and edit your account if you would like to make changes, or use a different Email Address. ";
                    thisvue.snackbar=true;
               }
               if(d=="inserted"){
                   thisvue.snackbarmsg="You have been automatically approved. Please click on the login tab and use your email and password to log in.";
                    thisvue.snackbar=true;
                    setTimeout(() => {  this.$router.replace({ name: "home" }); }, 10000);

               }

                //window.console.log(d);
                //thisvue.$parent.authent = true;
                //thisvue.$router.replace({ name: "home" });
                
            })
          .catch(function (error) {
              window.console.log(error);
                thisvue.dialog = false;
                    thisvue.response = true;
                    thisvue.dups = true;
                    thisvue.msg = "There was an error processing this request, please try again or contact the City of Bremerton for further assistance.";
                    thisvue.ttl = "Processing Error";
            });
        }
     },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    gohome(a){
       this.$store.commit('setacct',a);
       this.$router.replace({ name: "Home" });
    }
       
  },
  mounted(){
      window.scrollTo(0,0);
  },
  
  
}
</script>