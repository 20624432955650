<template>
  <v-app>

    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      top
      shaped
      dark
      :color="sbcolor"
    >
    <v-row justify="center">
    {{sbtext}}
    </v-row>

    </v-snackbar>

<div>
  <v-layout row justify-center>
   <!-- --------------------------------------------updated data Popup---------------------------------------->       
        <v-dialog
        v-model="dialogUpdate"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title>Updated Account Information</v-card-title>
            <v-card-text>
              <v-divider></v-divider>
             <v-row justify="center" class="pa-4">
               <v-row justify="center">Thank you for updating you information!</v-row>
               <v-row justify="center">The City of Bremerton will apply you updates upon review.</v-row>
                
                
               
             </v-row>
            </v-card-text>
            <v-card-actions>
              
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="endupdate">Okay</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>  
    <!-- --------------------------------------------Non Profit Popup---------------------------------------->       
        <v-dialog
        v-model="dialogNP"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title> Non Profit Type</v-card-title>
            <v-card-text>
              <v-radio-group v-model="NonP" @change="changeNP">
                <v-radio value="Y" label="Yes"></v-radio>
                <v-radio value="N" label="No"></v-radio>
              </v-radio-group>
            </v-card-text>
            <v-card-actions>
              
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="dialogNP = false">cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>  
<!-- --------------------------------------------Frequency Popup---------------------------------------->       
        <v-dialog
        v-model="dialogFr"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title>Filing Frequency</v-card-title>
            <v-card-text>
              <v-radio-group v-model="Freq" @change="changefreq">
                <!--
                <v-radio value="M" label="Monthly"></v-radio>
                <v-radio value="Q" label="Quarterly"></v-radio>
                <v-radio value="A" label="Annual"></v-radio>
                -->
                <p>
                  To change your filing frequency, please contact the city. Thanx!
                </p>
              </v-radio-group>
            </v-card-text>
            <v-card-actions>
              
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="dialogFr = false">cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>       
<!-- --------------------------------------------Owner Type Popup---------------------------------------->       
        <v-dialog
        v-model="dialogOT"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title> Owner Type</v-card-title>
            <v-card-text>
              <v-radio-group v-model="TypeOwner" @change="ownertype">
                <v-radio value="C" label="Corperation"></v-radio>
                <v-radio value="L" label="LLC"></v-radio>
                <v-radio value="P" label="Partnership"></v-radio>
                <v-radio value="S" label="Sole Proprietorship"></v-radio>
                <v-radio value="G" label="Government Entity"></v-radio>

              </v-radio-group>
            </v-card-text>
            <v-card-actions>
              
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="dialogOT = false">cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>    
<!-- --------------------------------------------Exemptions Popup---------------------------------------->       
        <v-dialog
        v-model="dialogUE"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title> Edit Exemption</v-card-title>
            <v-card-text>
              <v-text-field v-model="upexNote" shaped dense label="Note" outlined ></v-text-field>
              <v-text-field v-model="upexDate" shaped dense label="Date of Note" outlined disabled=""></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="success" @click="updateEx2" >update</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="dialogUE = false">cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!-- --------------------------------------------Notes Popup---------------------------------------->
        <v-dialog
        v-model="dialogUN"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title> Edit Note</v-card-title>
            <v-card-text>
              <v-text-field v-model="upnoNote" shaped dense label="Note" outlined ></v-text-field>
              <v-text-field v-model="upnoDate" shaped dense label="Date of Note" outlined disabled=""></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="success" @click="updatenotes2" >update</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="warning" @click="dialogUN = false">cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
<!-- --------------------------------------------Address Popups---------------------------------------->
  <!-----------------------Delete address check verification------------->
          <v-dialog
        v-model="dialogDAcheck"
        max-width="500"
        >
          <v-card width="500">
            <v-card-title> Are you Sure you want to delete this Addresses?</v-card-title>
            
            <v-card-actions>
              <v-btn text color="error" @click="deladd" >DELETE</v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="success" @click="dialogDAcheck = false">cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  <!-----------------------------Update Address---------------------------->      
        <v-dialog
        v-model="dialogUA"
        max-width="700"
        shaped
        >
          <v-card
              shaped
              elevation="12"
              :color="bgcolor4"
              >
                <v-card-title>
                  Other Address
                  <v-spacer></v-spacer>
                  
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRtype" dense label="Type" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRmisc" dense label="Misc" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRname" dense label="Name" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRtitle" dense label="Title" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mx-4">
                  <v-text-field label="Address line 1" :background-color="bgcolor4" v-model="ADDRa1"  dense placeholder=" " ></v-text-field>
                  </v-row>
                  <v-row class="mx-4">
                  <v-text-field label="Address line 2" :background-color="bgcolor4" v-model="ADDRa2"  dense placeholder=" " ></v-text-field>
                  </v-row>
                  <v-row class="mx-4">
                  <v-text-field label="Address line 3" :background-color="bgcolor4" v-model="ADDRa3"  dense placeholder=" " ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRcity" dense label="City" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRstate" dense label="State" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRzip" dense label="Zip" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRfax" dense label="Fax" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="my-0 mx-0 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRphone" dense label="Phone" outlined ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 mx-0 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRext" dense label="Extention" outlined ></v-text-field>
                    </v-col>
                    <v-col  class="my-0 mx-0 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="ADDRemail" dense label="Email" outlined ></v-text-field>
                    </v-col>
                    
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="updateAd2()" text color="warning" >Update</v-btn>
                  <v-btn text color="error" @click="dialogDAcheck=true">DELETE</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="success" @click="dialogUA=false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>
     <!----------------------------------------Add Address----------------------->  
         <v-dialog
        v-model="dialogAA"
        max-width="700"
        shaped
        >
          <v-card
              shaped
              elevation="12"
              :color="bgcolor4"
              >
                <v-card-title>
                  New Address
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.type" dense label="Type" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.misc" dense label="Misc" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.name" dense label="Name" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.title" dense label="Title" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                  <v-text-field :background-color="bgcolor4" v-model="OADDR.a1"  dense   label="Address line 1"></v-text-field>
                  </v-row>
                  <v-row>
                  <v-text-field :background-color="bgcolor4" v-model="OADDR.a2"  dense  label="Address line 2" ></v-text-field>
                  </v-row>
                  <v-row>
                  <v-text-field :background-color="bgcolor4" v-model="OADDR.a3"  dense label="Address line 3"></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.city" dense label="City"  ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.state" dense label="State"  ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.zip" dense label="Zip"  ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.fax" dense label="Fax" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="my-0 mx-0 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.phone" dense label="Phone" outlined ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="my-0 mx-0 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.ext" dense label="Extention" outlined ></v-text-field>
                    </v-col>
                    <v-col  class="my-0 mx-0 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="OADDR.email" dense label="Email" outlined ></v-text-field>
                    </v-col>
                    
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="adad2()" text color="warning" >Add New Address</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="success" @click="dialogAA=false">Cancel</v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>

    

  </v-layout>
</div>

 <v-sheet width="100%" height="50" color="teal darken-2">
   <v-row>
    
     <v-col>
       <small>
         <!--  change font color    -->
            <div class="ml-3 mt-2 green--text text--lighten-3">
              Please make whatever changes you need and then click "UPDATE INFORMATION"<br/>
              Updates will not be shown on your account until they are approved by the city
            </div>
       </small>
      </v-col>
      <v-col cols="3">
        <v-hover v-slot:default="{ hover }" >
          <v-row class="mt-2 mr-4" justify="end">
            <v-btn color="brown darken-4" dark  background-color="red" @click="updatedata"  :elevation="hover ? 24 : 12">Update Information</v-btn>
          </v-row>  
          </v-hover >
      </v-col>
    </v-row>
  </v-sheet>

<v-row justify="center">  
  
<v-sheet max-width="1000" class="mt-12" min-width="1000" >
   <div id="maindiv">
     <v-row align="center">
      
     </v-row>
   
     <v-container class="grey lighten-5" fluid>
       <v-fab-transition>
       <v-form
        ref="form"
        v-if="showform"
        >
          <v-row no-gutters>
            <v-col>
              <v-row >
                <v-col>
                  <v-text-field  v-model="taxid" :rules="emptyRules" shaped dense label="Tax ID" outlined ></v-text-field>
                  <v-text-field  v-model="acct" :rules="emptyRules" shaped readonly dense hint="You can not change your account number" label="Account No" outlined ></v-text-field>
                  
                </v-col>
                <v-col>
                  <v-row class="mt-0 mr-2"><v-text-field :rules="emptyRules" shaped v-model="licyear" hint="You can not change the license year" readonly dense label="License Year" outlined ></v-text-field></v-row>
                  
                  <v-row class="mr-2"><v-text-field  v-model="Email" :rules="emptyRules" shaped dense label="Email" outlined ></v-text-field></v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :background-color="bgcolor3"  v-model="name" :rules="emptyRules" shaped dense label="Business Name" outlined ></v-text-field>
                  <v-text-field :background-color="bgcolor3"  v-model="dba" :rules="emptyRules" shaped dense label="Doing Business As (DBA)" outlined ></v-text-field>
                  
                </v-col>
              </v-row>

            </v-col>
            <v-col >
              <v-row :background-color="bgcolor">
                <v-col  class="ma-3 pa-0">
                  <v-text-field  class="ma-0 pa-0" :background-color="bgcolor" v-model="NonP" readonly @click="dialogNP=true" :rules="emptyRules" shaped dense label="Non Profit" outlined ></v-text-field>
                  
                </v-col>
                <v-col  class="ma-3 pa-0">
                  <v-text-field  class="ma-0 pa-0" 
                                  persistent-placeholder 
                                  placeholder=" " 
                                  :background-color="bgcolor" 
                                  v-model="Freq" 
                                  :rules="emptyRules" 
                                  shaped 
                                  readonly 
                                  @click="dialogFr = true" 
                                  dense 
                                  label="Frequency" 
                                  outlined ></v-text-field>
                  
                </v-col>
                <v-col  class="ma-3 pa-0">
                  <v-text-field  class="ma-0 pa-0" :background-color="bgcolor" v-model="TypeOwner" :rules="emptyRules" readonly @click="dialogOT = true" shaped dense label="Owner Type" outlined ></v-text-field>
                  
                </v-col>
                <v-col  class="ma-3 pa-0">
                  <v-text-field  class="ma-0 pa-0" :background-color="bgcolor" type="number" v-model="SqFoot" :rules="emptyRules" shaped dense label="Sq Footage" outlined ></v-text-field>
                  <!--
                  <v-text-field :background-color="bgcolor" v-model="Fein" :rules="emptyRules" shaped dense label="FEIN" outlined ></v-text-field>
                  <v-text-field :background-color="bgcolor" v-model="Password" :rules="emptyRules" shaped dense label="Note" outlined ></v-text-field>
                  <v-text-field :background-color="bgcolor" v-model="clas" :rules="emptyRules" shaped dense label="Class" outlined ></v-text-field>
                  -->
                  
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="3" class="ma-0 pa-0">
                  <v-text-field :background-color="bgcolor" type="number" v-model="NoOfEmp" :rules="emptyRules" shaped dense label="# of Emp" outlined ></v-text-field>
                </v-col>
                <v-col class="my-0 ml-2 pa-0">
                  <v-text-field :background-color="bgcolor4" v-model="Web" persistent-placeholder placeholder=" " dense label="Website" outlined ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="ma-0 pa-0">
                  <v-text-field class="mt-3 mr-3 ml-4" :background-color="bgcolor2" v-model="bgroup.Title" :rules="emptyRules" shaped readonly dense label="Business Group" outlined >
                    <template v-slot:prepend>
                      <v-tooltip
                        bottom
                      >
                         <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-icon >mdi-help-circle-outline</v-icon>
                            </div>
                        </template>
                        {{bgroup.Description}}
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="my-0 mx-1 py-0">
                  <v-text-field :background-color="bgcolor2" v-model="bclass.Title" :rules="emptyRules" shaped readonly dense label="Business Class" outlined >
                    <template v-slot:prepend>
                      <v-tooltip
                        bottom
                      >
                         <template v-slot:activator="{ on }">
                            <div v-on="on">
                              <v-icon >mdi-help-circle-outline</v-icon>
                            </div>
                        </template>
                        {{bclass.Description}}
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          
          </v-row>
          <v-row>
            <v-col class="my-0 mx-1 py-0">
              <v-text-field :background-color="bgcolor3"  v-model="busdes" :rules="emptyRules" shaped dense label="Business Description" outlined ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card
              shaped
              elevation="12"
              :color="bgcolor4"
              >
                <v-card-title>
                  Mailing Address
                </v-card-title>
                <v-card-text>
                  <v-text-field :background-color="bgcolor4" v-model="MAline1"  dense text ></v-text-field>
                  <v-text-field :background-color="bgcolor4" v-model="MAline2"  dense   ></v-text-field>
                  <v-text-field :background-color="bgcolor4" v-model="MAline3"  dense   ></v-text-field>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="MAcity" dense label="City" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="MAstate" dense label="State" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="MAzip" dense label="Zip" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="MAphone" dense label="Phone" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="MAfax" dense label="Fax" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>
            </v-col>
            <v-col>
               <v-card
              shaped
              elevation="12"
              :color="bgcolor4"
              >
                <v-card-title>
                  Physical Address
                </v-card-title>
                <v-card-text>
                  <v-text-field :background-color="bgcolor4" v-model="PAline1"  dense text ></v-text-field>
                  <v-text-field :background-color="bgcolor4" v-model="PAline2"  dense   ></v-text-field>
                  <v-text-field :background-color="bgcolor4" v-model="PAline3"  dense   ></v-text-field>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="PAcity" dense label="City" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="PAstate" dense label="State" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="PAzip" dense label="Zip" outlined ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-0 mx-1 py-0">
                      <v-text-field :background-color="bgcolor4" v-model="PAphone" dense label="Phone" outlined ></v-text-field>
                    </v-col>
                    <v-col class="my-0 mx-1 py-0">
                      
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
       </v-form>
       </v-fab-transition>
     </v-container>
  </div>
 
  <div id="tabdiv" v-if="tables">
    
    <template>
     
      <v-card>
        <v-tabs
          dark
          background-color="teal darken-3"
          show-arrows
          v-model="tab"
          centered
        >
          <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
<!--
          <v-tab href="#Transactions">
            Transactions
          </v-tab>
          

          <v-tab href="#Exemptions" @click="getexemptions">
            Exemptions
          </v-tab>

-->

          <v-tab href="#Addresses" @click="getaddresses">
            Other Addresses
          </v-tab>
          
          <v-tab href="#Documents">
            Portal Activity
          </v-tab>
        </v-tabs>
         <v-tabs-items v-model="tab">

<!--           
          <v-tab-item value="Transactions">
            
            
              <template>
                <v-data-table 
                  dense 
                  :headers="transheaders" 
                  :items="transactions" 
                  item-key="name" 
                  class="elevation-1">
                  </v-data-table>
              </template>


          </v-tab-item>
           <v-tab-item value="Activity">
            
             <template>
                <v-data-table 
                  dense 
                  :headers="activityheaders" 
                  :items="activities" 
                  item-key="name" 
                  class="elevation-1">
                  </v-data-table>
              </template>

          </v-tab-item>
          <v-tab-item value="Summary">
            summary
          </v-tab-item>
          <v-tab-item value="Exemptions">
            <v-row>
              <v-col>
                <v-row justify="center">
                  <template>
                    <v-simple-table height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Exemption <small>(click to edit)</small></th>
                            <th class="text-left">Entery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in exemptions" :key="item.prikey" @click="updateEx(item.prikey,item.note,item.entryDate,item.acntNo)">
                            <td>{{ item.note }}</td>
                            <td>{{ item.entryDate }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-row>
              </v-col>
              
            </v-row>
          </v-tab-item>

-->

          <v-tab-item value="Addresses">
             <v-row justify="center">
               <template>
                 <v-sheet width="1000"  color="teal lighten-4">
                  <v-row class="mt-2">
                    <v-btn color="teal darken-4" elevation="12" dark class="mx-8 mt-2" @click="dialogAA=true">Add New Address</v-btn>
                    <small class="mt-4">click on an address below to edit or delete it</small>
                      <v-col cols="12">
                          <v-simple-table >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Addr Type</th>
                                  <th class="text-left">Line1</th>
                                  <th class="text-left">Line2</th>
                                  <th class="text-left">Line3</th>
                                  <th class="text-left">City</th>
                                  <th class="text-left">State</th>
                                  <th class="text-left">Zip</th>
                                  <th class="text-left">Title</th>
                                  <th class="text-left">Name</th>
                                  <th class="text-left">Email</th>
                                  <th class="text-left">Phone</th>
                                  <th class="text-left">Ext</th>
                                  <th class="text-left">Fax</th>
                                  <th class="text-left">Misc</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in addresses" :key="item.prikey" @click="updateAd(item)">
                                  <td>{{ item.AddrType }}</td>
                                  <td>{{ item.AddrOtherAdr1 }}</td>
                                  <td>{{ item.AddrOtherAdr2 }}</td>
                                  <td>{{ item.AddrOtherAdr3 }}</td>
                                  <td>{{ item.AddrOtherCity }}</td>
                                  <td>{{ item.AddrOtherState }}</td>
                                  <td>{{ item.AddrOtherZip }}</td>
                                  <td>{{ item.AddrOtherTitle }}</td>
                                  <td>{{ item.AddrOtherName }}</td>
                                  <td>{{ item.AddrOtherEMail }}</td>
                                  <td>{{ item.AddrOtherPhone }}</td>
                                  <td>{{ item.AddrOtherExt }}</td>
                                  <td>{{ item.AddrOtherFax }}</td>
                                  <td>{{ item.AddrOtherMisc }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                      </v-col>
                    </v-row>
                </v-sheet>
              </template>
            </v-row>
          </v-tab-item>

<!--

          <v-tab-item value="Conditions">
            <v-row justify="center" class="pa-8">
              <v-card 
              elevation="12"
              shaped
              min-width="500"
              max-width="500"
              :color="bgcolor4"
              >
                <v-card-title>Condition </v-card-title>
                <v-card-text>
                  <v-textarea
                    outlined
                    auto-grow
                    v-model="condition"
                  ></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <small> (this will print on the license)</small>
                  <v-spacer></v-spacer>
                  <v-btn text @click="updateCondition">Update</v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-tab-item>
-->
          <v-tab-item value="Documents">
            Under Construction
          </v-tab-item>
          
          <!--
          <v-tab-item value="Notes">
             <v-row>
              <v-col>
                <v-row justify="center">
                  <template>
                    <v-simple-table height="300px">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Note <small>(click to edit)</small></th>
                            <th class="text-left">Entery Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in Notes" :key="item.prikey" @click="updatenotes(item.prikey,item.note,item.entryDate,item.acntNo)">
                            <td>{{ item.note }}</td>
                            <td>{{ item.entryDate }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-row>
              </v-col>
              <v-col>
                <v-row justify="center">
                  <v-card min-width="400" :color="bgcolor4" elevation="12">
                    <v-card-title>Add Note</v-card-title>
                    <v-card-text>
                      <v-text-field width="300" v-model="newNo" dense outlined></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer><v-btn text @click="insertnotes" >Submit</v-btn>
                    </v-card-actions>

                  </v-card>
                </v-row>  
              </v-col>
            </v-row>
          </v-tab-item>
          -->
        </v-tabs-items>
      </v-card>
    </template>
  </div> 
  
 </v-sheet>
 </v-row> 
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => {
     return ({
       tab:'',
       tables:true,
       bgcolor:'teal lighten-5',
       bgcolor2:'green lighten-5',
       bgcolor3:'amber lighten-5',
       bgcolor4:'blue-grey lighten-5',
       bgroup:[],
       bclass:[],
       exemptions:[],
       addresses:[],
       dialogUpdate:false,
       dialogNP:false,
       dialogFr:false,
       dialogOT:false,
       dialogUE:false,
       dialogUN:false,
       dialogUA:false,
       dialogAA:false,
       dialog:true,
       dialogDAcheck:false,
       upnoPrikey:'',
       upnoNote:'',
       upnoDate:'',
       upexPrikey:'',
       upexNote:'',
       upexDate:'',
       upexAccnt:'',
       snackbar:false,
       sbtext:"hi",
       sbcolor:'',
       newNo:'',
       newEx:'',
       OADDR:{type:'',misc:'',name:'',title:'',a1:'',a2:'',a3:'',city:'',state:'',zip:'',fax:'',phone:'',ext:'',email:''},
       ADDRacnt:'',
       ADDRacntDb:'',
       ADDRtype:'',
       ADDRa1:'',
       ADDRa2:'',
       ADDRa3:'',
       ADDRcity:'',
       ADDRstate:'',
       ADDRzip:'',
       ADDRemail:'',
       ADDRext:'',
       ADDRfax:'',
       ADDRmisc:'',
       ADDRname:'',
       ADDRphone:'',
       ADDRtitle:'',
       ADDRprikey:'',
       condition:'',
       businessprikey:'',
        showform:true,
        taxid:'',
        loc:'',
        licyear:'',
        acct:'',
        acctdb:'',
        status:'',
        user:'',
        name:'',
        dba:'',
        busdes:'',
        clas:'',
        MAline1:'',
        MAline2:'',
        MAline3:'',
        MAcity:'',
        MAstate:'',
        MAzip:'',
        MAphone:'',
        MAfax:'',
        PAline1:'',
        PAline2:'',
        PAline3:'',
        PAcity:'',
        PAstate:'',
        PAzip:'',
        PAphone:'',
        PAfax:'',
        TypeOwner:'',
        NonP:'',
        Freq:'',
        Open:'',
        close:'',
        Email:'',
        Web:'',
        NoOfEmp:'',
        SqFoot:'',
        Password:'',
        Parcel:'',
        Notes:[],
        transheaders: [
            { text: 'TransDate', value: 'TransDate.date' },
            { text: 'BatchSeq', value: 'BatchSeq' },
            { text: 'TransActStatus', value: 'TransActStatus' },
            { text: 'Batchid', value: 'Batchid' },
            { text: 'TransCode', value: 'TransCode' },
            { text: 'TransComment',value: 'TransComment'},
            { text: 'TransCreatedBy', value: 'TransCreatedBy' },
            { text: 'TransEntrySeq', value: 'TransEntrySeq' },
            { text: 'TransReference', value: 'TransReference' },
            { text: 'UserName', value: 'UserName' }
            
          ],
        activityheaders: [
          { text: 'ActBaseAmt', value: 'ActBaseAmt' },
          { text: 'ActBatchDay', value: 'ActBatchDay' },
          { text: 'ActBatchSeq', value: 'ActBatchSeq' },
          { text: 'ActCodeType', value: 'ActCodeType' },
          { text: 'ActDeduction',value: 'ActDeduction'},
          { text: 'ActDistribAmt', value: 'ActDistribAmt' },
          { text: 'ActEndingYM', value: 'ActEndingYM.date' },
          { text: 'ActEntrySeq', value: 'ActEntrySeq' },
          { text: 'ActGrossAmt', value: 'ActGrossAmt' },
          { text: 'ActPeriod', value: 'ActPeriod' },
          { text: 'ActProcessYM', value: 'ActProcessYM' },
          { text: 'ActReference:null', value: 'ActReference:null' },
          { text: 'ActRevLoc', value: 'ActRevLoc' },
          { text: 'ActRevType', value: 'ActRevType' },
          { text: 'ActTCode', value: 'ActTCode' },
          { text: 'Batchid', value: 'Batchid' }
        ],
        transactions:[],
        activities:[],
        Fein:'',
        emptyRules:[
          v => !!v || 'No Value',
          v => v !='null' || 'No Value'
        ]
    })
  },
  
  methods:{
        validate () {
        this.$refs.form.validate()
      },
      changeNP(){
        this.dialogNP=false;
      },
      changefreq(){
        this.dialogFr=false;
      },
      ownertype(){
        this.dialogOT=false;
      },
      endupdate(){
        this.dialogUpdate=false;
        this.$router.replace({ name: "Home" });
      },
      updatedata(){
        
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'updatedata');
          params.append('prikey', this.businessprikey);
          params.append('AcctNo', this.acct);
          params.append('AcctDb', this.acctdb);
          params.append('TaxId',thisvue.taxid);//
          params.append('Homeloc',thisvue.loc);// 
          params.append('LicYear',thisvue.licyear);// 
          params.append('BusName',thisvue.name);//
          params.append('DBAName',thisvue.dba);//
          params.append('BusDesc',thisvue.busdes);//
          params.append('ClassCode',thisvue.clas);
          params.append('LocAdr1',thisvue.PAline1);//
          params.append('LocAdr2',thisvue.PAline2);//
          params.append('LocAdr3',thisvue.PAline3);//
          params.append('LocCity',thisvue.PAcity);//
          params.append('LocState',thisvue.PAstate);//
          params.append('LocZip',thisvue.PAzip);//
          params.append('LocPhone',thisvue.PAphone);// 
          params.append('Phone',thisvue.MAphone);//
          params.append('FaxPhone',thisvue.MAfax);// 
          params.append('MailAdr1',thisvue.MAline1);//
          params.append('MailAdr2',thisvue.MAline2);//
          params.append('MailAdr3',thisvue.MAline3);//
          params.append('MailCity',thisvue.MAcity);//
          params.append('MailState',thisvue.MAstate);//
          params.append('MailZip',thisvue.MAzip);//
          params.append('Nonprofit',thisvue.NonP);//
          params.append('TypeOwner',thisvue.TypeOwner);
          params.append('Freq',thisvue.Freq);
          params.append('DateOpen',thisvue.Open);
          params.append('DateClosed',thisvue.close);
          params.append('EMail',thisvue.Email);
          params.append('WebSite',thisvue.Web); 
          params.append('Count1',thisvue.NoOfEmp);
          params.append('Count4',thisvue.SqFoot);
          params.append('Note1',thisvue.Password);
          params.append('Note2',thisvue.Parcel);
          params.append('FEIN',thisvue.Fein);
          //params.append('Conditions',thisvue.condition);

          
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.dialogUpdate=true;
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
            thisvue.dialogUN = false;
            //thisvue.getnotes();
          });


      },
      getactivities(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let acnt=this.$store.state.acnt;
          let acntdb=this.$store.state.acntDb;
          params.append('ty', 'getactivities');
          params.append('acnt', acnt);
          params.append('acntDb',acntdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            thisvue.activities = e;
          });
      },
      gettransactions(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let acnt=this.$store.state.acct.AcctNo;
          let acntdb=this.$store.state.acct.AcctDb;
          params.append('ty', 'gettransactions');
          params.append('acnt', acnt);
          params.append('acntDb',acntdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            thisvue.transactions = e;
          });
      },
      getnotes(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'notes');
          params.append('acnt', this.acct);
          params.append('acntDb', this.acctdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            thisvue.Notes = e;
          });
      },
      updatenotes(p,n,e,a){
        this.upnoPrikey = p;
        this.upnoNote = n;
        this.upnoAccnt = a;
        let d = new Date;
          d=d.toLocaleString();
        if(e){
          this.upnoDate = e;
        }else{
          this.upnoDate = d;
        }
        this.dialogUN=true;
      },
      updatenotes2(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let d=this.upnoDate;
          d=d.replace(',','');
          params.append('ty', 'upno');
          params.append('entrydate', d);
          params.append('prikey', this.upnoPrikey);
          params.append('note', this.upnoNote);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.snackbar=true;
              thisvue.sbtext="Succes!!";
              thisvue.sbcolor="green";
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
            thisvue.dialogUN = false;
            thisvue.getnotes();
          });
      },
      insertnotes(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let d=new Date;
            d=d.toLocaleString();
            d=d.replace(',','');
          params.append('ty', 'adno');
          params.append('entrydate', d);
          params.append('note', this.newNo);
          params.append('acnt', this.acct);
          params.append('acntDb', this.acctdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.snackbar=true;
              thisvue.sbtext="Succes!!";
              thisvue.sbcolor="green";
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
              thisvue.newNo='';
              thisvue.getnotes();
          });
      },
      updateCondition(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
         
          params.append('ty', 'upco');
          params.append('cond', this.condition);
          params.append('acnt', this.acct);
          params.append('acntDb', this.acctdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.snackbar=true;
              thisvue.sbtext="Succes!!";
              thisvue.sbcolor="green";
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
              
          });
      },
      insertEx(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let d=new Date;
            d=d.toLocaleString();
            d=d.replace(',','');
          params.append('ty', 'adex');
          params.append('entrydate', d);
          params.append('note', this.newEx);
          params.append('acnt', this.acct);
          params.append('acntDb', this.acctdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.snackbar=true;
              thisvue.sbtext="Succes!!";
              thisvue.sbcolor="green";
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
              thisvue.newEx='';
              thisvue.getexemptions();
          });
      },
      updateEx2(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let d=this.upexDate;
          d=d.replace(',','');
          params.append('ty', 'upex');
          params.append('entrydate', d);
          params.append('prikey', this.upexPrikey);
          params.append('note', this.upexNote);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.snackbar=true;
              thisvue.sbtext="Succes!!";
              thisvue.sbcolor="green";
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
            thisvue.dialogUE = false;
            thisvue.getexemptions();
          });
      },
      updateEx(p,n,e,a){
        this.upexPrikey = p;
        this.upexNote = n;
        this.upexAccnt = a;
        let d = new Date;
          d=d.toLocaleString();
          this.upexDate = d;
        this.dialogUE=true;
      },
      deladd(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'deladd');
          params.append('prikey',this.ADDRprikey);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            if(e == "okay"){
              thisvue.snackbar=true;
              thisvue.sbtext="Succes!!";
              thisvue.sbcolor="green";
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
            thisvue.dialogUA = false;
            thisvue.dialogDAcheck = false;
            thisvue.getaddresses();
          });
      },
      adad(){
        /*
        //this.ADDRacnt=acnt;
          this.ADDRtype='';
          this.ADDRa1='';
          this.ADDRa2='';
          this.ADDRa3='';
          this.ADDRcity='';
          this.ADDRstate='';
          this.ADDRzip='';
          this.ADDRemail='';
          this.ADDRext='';
          this.ADDRfax='';
          this.ADDRmisc='';
          this.ADDRname='';
          this.ADDRphone='';
          this.ADDRtitle='';
          this.dialogAA=true;
          */
      },
      adad2(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          this.OADDR.BusName = this.name;
          let o=JSON.stringify(this.OADDR);
          params.append('ty', 'adad');
          params.append('acntno',this.acct);
          params.append('acntDb',this.acctdb);
          params.append('otherAdd',o);

          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            window.console.log(e);
            if(e == "okay"){
              thisvue.dialogUpdate=true;
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red";
            }
            thisvue.dialogAA = false;
            
          });
      },
      updateAd(item){

        this.ADDRacnt=item.AcctNo;
        this.ADDRacntDb=item.AcctDb;
        this.ADDRtype=item.AddrType;
        this.ADDRa1=item.AddrOtherAdr1;
        this.ADDRa2=item.AddrOtherAdr2;
        this.ADDRa3=item.AddrOtherAdr3;
        this.ADDRcity=item.AddrOtherCity;
        this.ADDRstate=item.AddrOtherState;
        this.ADDRzip=item.AddrOtherZip;
        this.ADDRemail=item.AddrOtherEMail;
        this.ADDRext=item.AddrOtherExt;
        this.ADDRfax=item.AddrOtherFax;
        this.ADDRmisc=item.AddrOtherMisc;
        this.ADDRname=item.AddrOtherName;
        this.ADDRphone=item.AddrOtherPhone;
        this.ADDRtitle=item.AddrOtherTitle;
        this.ADDRprikey=item.prikey;
        this.dialogUA=true;
      },
      updateAd2(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'upad');
          params.append('acnt',this.ADDRacnt);
          params.append('acntDb',this.ADDRacntDb);
          params.append('BusName',this.name);
          params.append('AddrType',this.ADDRtype);
          params.append('AddrOtherAdr1',this.ADDRa1);
          params.append('AddrOtherAdr2',this.ADDRa2);
          params.append('AddrOtherAdr3',this.ADDRa3);
          params.append('AddrOtherCity',this.ADDRcity);
          params.append('AddrOtherState',this.ADDRstate);
          params.append('AddrOtherZip',this.ADDRzip);
          params.append('AddrOtherEMail',this.ADDRemail);
          params.append('AddrOtherExt',this.ADDRext);
          params.append('AddrOtherFax',this.ADDRfax);
          params.append('AddrOtherMisc',this.ADDRmisc);
          params.append('AddrOtherName',this.ADDRname);
          params.append('AddrOtherPhone',this.ADDRphone);
          params.append('AddrOtherTitle',this.ADDRtitle);
          params.append('prikey',this.ADDRprikey);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            window.console.log(e);
            if(e == "okay"){
              thisvue.dialogUpdate=true;
            }else{
              thisvue.snackbar=true;
              thisvue.sbtext="Error!!";
              thisvue.sbcolor="red"
            }
            //thisvue.dialogUA = false;
            //thisvue.getaddresses();
          });
      },
      getaddresses(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'ad');
          params.append('acntno', this.acct);
          params.append('acntDb', this.acctdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            thisvue.addresses = e;
          });
        },
        getexemptions(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'ex');
          params.append('acnt', this.acct);
          params.append('acntDb', this.acctdb);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let e = response.data;
            thisvue.exemptions = e;
          });
        },
        getclas(){
          let thisvue=this;
                 
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          params.append('ty', 'clas');
          params.append('clas', this.clas);
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            let cg = response.data;
            if(cg.length>1){      
              thisvue.bclass=cg[0];
              thisvue.bgroup=cg[1];
            }else{
              thisvue.bclass=cg[0];

              thisvue.bgroup={'code':thisvue.clas,'Description':'NA','Title':'NA'};
            }
          });
        },
        getdetails(){
          let thisvue=this;
          let params = new URLSearchParams();
          let url = this.$store.state.url;
          let prikey = this.$store.state.acct.fprikey
          params.append('ty', 'acntDetails');
          params.append('prikey', prikey);
          
          axios({
              method:"post",
              url:url,
              data:params
          })
          .then(function(response){
            thisvue.businessprikey = response.data.prikey;
            thisvue.taxid = response.data.TaxId;
            thisvue.loc = response.data.Homeloc;
            thisvue.licyear = response.data.LicYear;
            thisvue.acct = response.data.AcctNo;
            thisvue.acctdb = response.data.AcctDb;
            thisvue.status = response.data.Status;
            thisvue.user = response.data.UserCode;
            thisvue.name = response.data.BusName;
            thisvue.dba = response.data.DBAName;
            thisvue.busdes = response.data.BusDesc;
            thisvue.clas = response.data.ClassCode;
            thisvue.PAline1 = response.data.LocAdr1;
            thisvue.PAline2 = response.data.LocAdr2;
            thisvue.PAline3 = response.data.LocAdr3;
            thisvue.PAcity = response.data.LocCity;
            thisvue.PAstate = response.data.LocState;
            thisvue.PAzip = response.data.LocZip;
            thisvue.PAphone = response.data.LocPhone;
            thisvue.MAphone = response.data.Phone;
            thisvue.MAfax = response.data.FaxPhone;
            thisvue.MAline1 = response.data.MailAdr1;
            thisvue.MAline2 = response.data.MailAdr2;
            thisvue.MAline3 = response.data.MailAdr3;
            thisvue.MAcity = response.data.MailCity;
            thisvue.MAstate = response.data.MailState;
            thisvue.MAzip = response.data.MailZip;
            thisvue.NonP = response.data.Nonprofit;
            thisvue.TypeOwner = response.data.TypeOwner;
            thisvue.Freq = response.data.Freq;
            thisvue.Open = response.data.DateOpen;
            thisvue.close = response.data.DateClosed;
            thisvue.Email = response.data.EMail;
            thisvue.Web = response.data.WebSite;
            thisvue.NoOfEmp = response.data.Count1;
            thisvue.SqFoot = response.data.Count4;
            thisvue.Password = response.data.Note1;
            thisvue.Parcel = response.data.Note2;
            thisvue.Fein = response.data.FEIN;
            thisvue.condition = response.data.Conditions;
          }).then(function(){
            thisvue.getclas();
            //thisvue.gettransactions();
            thisvue.getaddresses();
            //thisvue.getexemptions();
           // thisvue.getnotes();
          });

        }
  },
  mounted(){
      window.scrollTo(0,0);
      let a= this.$store.state.authent;
      if(!a) {
        this.$router.replace({ name: "login" });
      }else{
        this.getdetails();
      }
  }
}
</script>

<style>
#maindiv{
  background-color:#D1F2EB 
}
</style>