<template>
  <v-app>

    <v-row justify="center" class="ma-8">
        
        <v-col>
            <v-row justify="center">
                
            
                <v-card
                min-width="610"
                max-width="610"
                elevation="12"
                >
                        <v-card-text>
                            
                            <v-sheet v-if="byphone">
                                <v-row class="mt-8" justify="center"><h1>Pay by Phone</h1></v-row>
                                <v-row class="mx-8 mt-8"><h2>document number {{docno}}</h2></v-row>
                                <v-row class="mx-8 mt-2"><b>To pay by phone, please call 360-473-5311</b></v-row>
                                <v-row class="mx-8">Please click the Create PDF button below  to save your tax record</v-row>
                                <v-row class="mx-8">Your tax document # is located on the bottom of the PDF file</v-row>
                                <v-row class="mx-8"><b><em>When on the phone please refer to the document number above</em></b></v-row>
                                <v-row class="mx-8 my-3">
                                    <v-col cols="5"><v-row class="mt-3"><v-divider></v-divider></v-row></v-col>
                                    <v-col> <v-row justify="center"><b>or</b> </v-row></v-col>
                                    <v-col cols="5"><v-row class="mt-3"><v-divider></v-divider></v-row></v-col>
                                </v-row>
                            </v-sheet>
                            <v-row class="mt-4" justify="center"><h1>Pay ${{this.$store.state.amountpaid.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}} by Mail</h1></v-row>
                            <v-row class="mx-8 mt-8"><b>Please print the PDF file, sign, include payment and mail to:</b></v-row>
                            <v-row class="mx-8">City of Bremerton</v-row>
                            <v-row class="mx-8">Tax & License Division</v-row>
                            <v-row class="mx-8">345 6th st</v-row>
                            <v-row class="mx-8">Suite 100</v-row>
                            <v-row class="mx-8">Bremerton WA 98337-1891</v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="ma-8">
                                
                                <v-btn 
                                    color="success"
                                    height="75"
                                    width="150"
                                    elevation="12"
                                    @click="printpdf">Create Pdf</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn 
                                    color="success" 
                                    height="75"
                                    width="150"
                                    elevation="12" 
                                    @click="goback">Done</v-btn>
                                    
                            </v-row>
                        </v-card-actions>
                        
                </v-card>
            </v-row>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-row justify="center"><pdf ref="pdf"></pdf> </v-row>
        </v-col>
    </v-row>
     
  </v-app>
</template>

<script>
import axios from "axios";
import pdf from "../components/pdfbf";
export default {
data(){
    return{
        byphone:false,
        maildata:[],
        mailaddress:[],
        l1:'',
        l2:'',
        l3:'',
        l4:'',
        l5:'',
        l6:'',
        docno:0,
     }
},
components: {
        pdf,
    },
methods:{
    getdocno(){
        let thisvue=this;
        let url=this.$store.state.url;
        const params = new URLSearchParams();
        params.append('ty', 'getdocno');
        params.append('note' , this.$store.state.paytime);
        params.append('acntno', this.$store.state.acct.AcctNo);
        axios({
            method:"post",
            url:url,
            data:params
        })
        .then(function(response){
            thisvue.docno=response.data; 
            //open dialog
            thisvue.dialogdownloadpdf2=true;
        });
    },
    goback(){
        this.$router.replace({ name: "Home" });
    },
    printpdf(){
        this.$refs.pdf.getdatans();
    },
},
mounted(){
        let a= this.$store.state.authent;
        if(!a) {
            this.$router.replace({ name: "login" });
        }
        this.getdocno();
        window.scrollTo(0,0);
        let m= this.$store.state.amountpaid;
        let mA=m.split("$");
        m=mA[1];
        if(parseInt(m)>10000){
            this.byphone=true;
        }else{
            this.byphone=false;
        }
    }
}
</script>

<style>

</style>